import { FC, useMemo } from "react";
import {
  ArrayInput,
  required,
  SelectInput,
  SimpleFormIterator,
  useGetList
} from "react-admin";
import { useWatch } from "react-hook-form";
import { commonItemsStyling } from "src/activities/commonStyling";
import { Activity, PublicationStatus } from "src/activities/types";

import { Pathway, PathwayActivity } from "../interfaces";
import { PathForm } from "./pathForm";

export const SidePaths: FC = () => {
  const { data } = useGetList<Activity & { id: string }>("activities", {
    filter: { status: PublicationStatus.active },
    sort: { field: "id", order: "ASC" }
  });

  const activities = useWatch<Pathway>({
    name: "mainPath.activities"
  }) as Array<PathwayActivity> | undefined;

  const choices = useMemo(
    () =>
      (activities ?? [])
        .filter((_, index) => index < activities.length - 1)
        .map((activity, index) => ({
          id: index,
          name: `#${index + 1}: ${
            data?.find((a) => a.id === activity.activityId)?.title
          }`
        })),
    [activities, data]
  );

  return (
    <ArrayInput source="sidePaths" disabled={(activities?.length || 0) < 1}>
      <SimpleFormIterator
        sx={commonItemsStyling}
        inline
        getItemLabel={(index) => `#${index + 1}`}
        className="items-list"
      >
        <SelectInput
          source={"startAfterIndex"}
          choices={choices}
          validate={required()}
          label="Start after"
          fullWidth
        />
        <PathForm source="activities" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
