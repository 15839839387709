import { Box } from "@mui/material";
import { Edit, SaveButton, TabbedForm, Toolbar, TopToolbar } from "react-admin";

import { useMutationErrorNotify } from "../common/handleMutationError";
import { commonToolbarStyling } from "./commonStyling";
import ActivityEvaluation from "./subforms/activityEvaluation";
import { ActivityItems } from "./subforms/activityItems";
import { ActivityOverview } from "./subforms/activityOverview";
import { ActivityProfessionalInformation } from "./subforms/activityProfessionalInformations";
import ActivityScoring from "./subforms/activityScoring";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";


const ActivityEditToolbar = () => (
  <Toolbar sx={commonToolbarStyling}>
    <SaveButton />
  </Toolbar>
);

const EditActions = () => (
  <TopToolbar></TopToolbar>
);

const ActivityEdit = () => {
  const notify = useMutationErrorNotify();

  return (
    <Edit
      actions={<EditActions />}
      title="Activity"
      transform={transformDataBeforeRequest}
      mutationMode="pessimistic"
      mutationOptions={{
        onError: notify
      }}
    >
      <TabbedForm toolbar={<ActivityEditToolbar />}>
        <TabbedForm.Tab label="Overview">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityOverview />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Professional Information">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityProfessionalInformation />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Items">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityItems />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Scoring">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityScoring />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Evaluation">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <ActivityEvaluation />
          </Box>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export default ActivityEdit;
