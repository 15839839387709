import { Language, PublicationStatus } from "src/activities/types";

export enum PathwayTimeRelation {
  RELATIVE = 'RELATIVE',
  ABSOLUTE = 'ABSOLUTE',
}


export interface PathwayActivity {
  id?: string;
  activityId: string;
  offsetInDays: number;
  shouldEndOnNextStart?: boolean;
}

export interface PathwayTrack {
  id?: string;
  startAfterIndex?: number;
  activities: Array<PathwayActivity>;
  timeRelation: PathwayTimeRelation;
}

export interface Pathway {
  id: string;
  title: string;
  practitionersInstruction?: string;
  indication: Array<string>;
  contraIndication: string;
  mainPath: PathwayTrack;
  sidePaths: Array<PathwayTrack>;
  status: PublicationStatus;
  language?: Language;
}