import { TextInputProps } from "react-admin";
import { Journal } from "src/journals/types";

import { Activity, ActivityItemType } from "./types";

export const answerOptionValueRequired = (
  value: string | undefined,
  allValues: Activity | Journal,
  props: TextInputProps
) => {
  const source = props.source.split(".");
  const itemIndex = Number('introduction' in allValues ? source[2] : source[1]);

if ('items' in allValues) {
    const itemType = allValues.items[itemIndex].activityItemType;
    if (itemType === ActivityItemType.PATIENT_RESPONSE_LIKERT_SCALE && !value) {
      return "Required for likert scale";
    }
    if (itemType === ActivityItemType.PATIENT_RESPONSE_MULTIPLE_CHOICE && value && value.includes(",")) {
      return "Commas are not allowed in multiple choice answers";
    }
  }

  if ('introduction' in allValues) {
    const itemType = allValues.introduction.items[itemIndex].activityItemType;
    if (itemType === ActivityItemType.PATIENT_RESPONSE_LIKERT_SCALE && !value) {
      return "Required for likert scale";
    }
    if (itemType === ActivityItemType.PATIENT_RESPONSE_MULTIPLE_CHOICE && value && value.includes(",")) {
      return "Commas are not allowed in multiple choice answers";
    }
  }
};
