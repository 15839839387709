import { FC, useEffect, useRef } from "react";
import { NumberInput, NumberInputProps } from "react-admin";

/**
 * Drop-in replacement for react-admin's Number Input
 * It disables value change on scroll
 * @param props NumberInputProps
 * @returns NumberInput
 */
export const CustomNumberInput: FC<NumberInputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (document.activeElement === inputRef.current) {
        event.preventDefault();
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return <NumberInput {...props} inputRef={inputRef} />;
};
