export enum UDIStatus {
  PROPOSED = "PROPOSED",
  WITHDRAWN = "WITHDRAWN",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
}

export interface UDI {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  mobileVersion: string;
  webVersion: string;
  apiVersion: string;
  adminPanelVersion: string;
  idpVersion: string;

  udiPi: string;
  udiDi: string;

  status: UDIStatus;

  expiresAt?: Date;
}

export type UDICreateDto = Omit<
  UDI,
  "id" | "createdAt" | "updatedAt" | "expiresAt"
>;
