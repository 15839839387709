import { Box } from "@mui/material";
import { Create, TabbedForm } from "react-admin";
import { useMutationErrorNotify } from "src/common/handleMutationError";

import { MainPath } from "./subforms/mainPath";
import { PathwayOverview } from "./subforms/pathwayOverview";
import { SidePaths } from "./subforms/sidePaths";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";

const PathwayCreate = () => {
  const notify = useMutationErrorNotify();

  return (
    <Create
      title="Pathway"
      mutationOptions={{
        onError: notify
      }}
      transform={transformDataBeforeRequest}
    >
      <TabbedForm>
        <TabbedForm.Tab label="Overview">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <PathwayOverview />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Main path">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <MainPath />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Side paths">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <SidePaths />
          </Box>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default PathwayCreate;
