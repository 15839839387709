import ArticleIcon from '@mui/icons-material/Article';

import JournalPageCreate from './journalPageCreate';
import JournalPageEdit from './journalPageEdit';
import JournalPageList from './journalPageList';
import { JournalPage } from './types';

export default {
  list: JournalPageList,
  edit: JournalPageEdit,
  create: JournalPageCreate,
  icon: ArticleIcon,
  recordRepresentation: (record: JournalPage) =>
    `${record.title}${record.version ? `, version: ${record.version}` : ""}, ${
      record.status
    }`,
    options: { label: "Journal Pages" },
};
