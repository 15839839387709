import { FC } from "react";

import { TableComponent } from "../InfoTable/TableComponent";
import { useDeletionRequestInfoTable } from "./hooks";
interface DeletionRequestInfoTableProps {
  activityId?: string;
  institutionId?: string;
  practitionerId?: string;
}

export const DeletionRequestInfoTable: FC<DeletionRequestInfoTableProps> = ({
  activityId,
  institutionId,
  practitionerId,
}) => {
  const { isLoading, lastMonth, tableConfig } = useDeletionRequestInfoTable(
    activityId,
    institutionId,
    practitionerId
  );

  return (
    <TableComponent
      headerLabel={"Patient account deletion requests"}
      columns={[
        "Deletion requests/patients total number",
        "Deletion requests, %",
      ]}
      isLoading={isLoading}
      lastMonth={lastMonth}
      tableConfig={tableConfig}
      disableChangeForColumns={[1]}
    />
  );
};
