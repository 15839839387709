import {
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  SelectField,
  TextField
} from "react-admin";

import { PolicyRole, PolicyStatus, PolicyType } from "./choices";

const PolicyList = () => (
  <List pagination={false}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
    >
      <SelectField source="type" choices={PolicyType} />
      <TextField source="version" />
      <SelectField source="role" choices={PolicyRole} />
      <SelectField source="status" choices={PolicyStatus} />
      <ReferenceField
        label="Applies to institution"
        source="institutionId"
        reference="institutions"
        emptyText="-"
      />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);

export default PolicyList;
