import { FC, PropsWithChildren, useState } from "react";

import { AppRoleContext } from "./AppRoleContext";

export const AppRoleContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [role, setRole] = useState<"SUPERUSER" | "CONTENT_CREATOR">(
    "CONTENT_CREATOR"
  );
  const ContextProvider = AppRoleContext.Provider;

  return (
    <ContextProvider value={{ role, setRole }}>{children}</ContextProvider>
  );
};
