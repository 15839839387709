import { Box } from "@mui/material";
import { Show, TabbedForm, TopToolbar } from "react-admin";

import { commonToolbarStyling } from "./commonStyling";
import ActivityStatistics from "./subforms/activityStatistics";

const ActivityShowToolbar = () => (
    <Box sx={commonToolbarStyling}>
    </Box>
  );

const ShowActions = () => (
    <TopToolbar></TopToolbar>
);

const ActivityShow = () => {
  return (
    <Show
    title="Activity"
    actions={<ShowActions />}
    >
        <TabbedForm toolbar={<ActivityShowToolbar />}>
            <TabbedForm.Tab label="Statistics">
                <Box
                    display="flex"
                    flexDirection="column"
                    width='100%'
                    paddingBottom="100px"
                    gap="3%"
                >
                    <ActivityStatistics />
                </Box>
            </TabbedForm.Tab>
      </TabbedForm>
    </Show>
  );
};

export default ActivityShow;
