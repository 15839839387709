import { Box } from "@mui/material";
import {
  BooleanInput,
  Create,
  DateInput,
  minLength,
  PasswordInput,
  required,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";

import { useMutationErrorNotify } from "../common/handleMutationError";
import { RequiredActionsChoices } from "./requiredActionsChoices";
import { transformCreateDataBeforeRequest } from "./transformDataBeforeRequest";

const PatientsCreate = () => {
  const notify = useMutationErrorNotify();

  return (
    <Create
      title="Patients"
      transform={transformCreateDataBeforeRequest}
      mutationOptions={{
        onError: notify,
      }}
    >
      <SimpleForm>
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: "100%", xl: 1414 }}
          gap="3%"
        >
          <TextInput
            source="email"
            type="email"
            validate={required()}
            fullWidth
          />
          <PasswordInput
            source="password"
            validate={[required(), minLength(8)]}
            fullWidth
          />
          <TextInput source="firstName" validate={required()} fullWidth />
          <TextInput source="lastName" validate={required()} fullWidth />
          <DateInput source="birthdate" validate={required()} fullWidth />
          <SelectArrayInput
            source="requiredActions"
            choices={RequiredActionsChoices}
          />
          <BooleanInput
            source="isPasswordTemporary"
            defaultValue={true}
            fullWidth
          />
          <BooleanInput
            source="isTesting"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default PatientsCreate;
