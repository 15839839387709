import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  SearchInput,
  TextField,
} from "react-admin";
import { Statistics } from "src/common/statistics";

import { DisableInstitutionButton } from "./disableInstitutionButton";

const InstitutionList = () => {
  return (
    <List
      aside={<Statistics />}
      filters={[<SearchInput source="q" alwaysOn key="search-input" />]}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" />
        <BooleanField source="isTesting" label="Testing" sortable={false} />
        <DisableInstitutionButton />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};

export default InstitutionList;
