import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { CustomDataProvider } from "../../../auth";
import { Patient } from "../../types";

export const RemoveFromPractitionerButton: FC = () => {
  const [open, setOpen] = useState(false);
  const { institutionId, practitionerId } = useParams();
  const { id, firstName, lastName } = useRecordContext<Patient>();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const { mutateAsync } = useMutation(() =>
    dataProvider.removePatientFromPractitioner(
      institutionId,
      practitionerId,
      id
    )
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await mutateAsync();
    refresh();
  };
  return (
    <>
        <ReactAdminButton
      label="Remove"
      onClick={(e) => {
        e.stopPropagation();
        void handleClick();
      }}
    />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {`Are you sure you want to unassign ${firstName} ${lastName}?`}
        </DialogTitle>
        <DialogActions>
          <ReactAdminButton
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              handleDialogClose();
            }}
          />
          <ReactAdminButton
            label="Confirm"
            onClick={(e) => {
              e.stopPropagation();
              void handleConfirm();
            }}
          />
        </DialogActions>
      </Dialog>
    </>

  );
};
