import { FC } from "react";

import { TableComponent } from "../InfoTable/TableComponent";
import { useOccurrenceInfoTable } from "./hooks";
interface OccurrenceInfoTableProps {
  activityId?: string;
  institutionId?: string;
  practitionerId?: string;
}

export const OccurrenceInfoTable: FC<OccurrenceInfoTableProps> = ({
  activityId,
  institutionId,
  practitionerId,
}) => {
  const { isLoading, lastMonth, tableConfig } = useOccurrenceInfoTable(
    activityId,
    institutionId,
    practitionerId
  );

  return (
    <TableComponent
      headerLabel={"Activity Occurrences"}
      columns={["Assigned", "Completed"]}
      isLoading={isLoading}
      lastMonth={lastMonth}
      tableConfig={tableConfig}
    />
  );
};
