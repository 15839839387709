import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button as MuiButton,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { FC, useContext, useState } from "react"; // Added useState for handling filter state
import {
  Button as ReactAdminButton,
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
  Title,
  useFilterState,
  useGetList,
  usePaginationState,
} from "react-admin";
import { Link, useLocation } from "react-router-dom";
import { Statistics } from "src/common/statistics";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import InstitutionEdit from "..";
import { commonDatagridStyling } from "../commonStyling";
import { AssignToInstitutionButton } from "./assignToInstitutionButton";
import { RemoveFromInstitutionButton } from "./removeFromInstitutionButton";

const CustomCreateButton = ({
  basePath,
  institutionId,
}: {
  basePath: string;
  institutionId?: string;
}) => (
  <ReactAdminButton
    component={Link}
    startIcon={<AddIcon />}
    to={`${basePath}/create?institutionId=${institutionId}`}
    label="Create"
  />
);

const InstitutionPractitionersList: FC = () => {
  const { role } = useContext(AppRoleContext);
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";
  const pagination = usePaginationState({ perPage: 5 });
  const { filter, setFilter } = useFilterState({});
  const [searchText, setSearchText] = useState("");

  const allPractitioners = useGetList("users/practitioners", {
    pagination,
    filter,
  });
  const practitionersInInstitution = useGetList(
    `institutions/${institutionId}/practitioners`,
    { pagination: { perPage: 1, page: 1 }}
  );

  const handleFilter = () => {
    setFilter(searchText);
  };

  const handleClearFilters = () => {
    setFilter('');
    setSearchText("");
  };

  return (
    <InstitutionEdit>
      <Box display="flex" flexDirection="column" gap="50px">
        <Title title="Practitioners" />
        <Box>
          <List
            resource={`institutions/${institutionId}/practitioners`}
            pagination={false} 
            aside={<Statistics resource={`institutions/${institutionId}/practitioners`}/>}
            title={" "}
            exporter={false}
            actions={
              role === "SUPERUSER" && (
                <CustomCreateButton
                  basePath={`/users/practitioners`}
                  institutionId={institutionId}
                />
              )
            }
          >
            {role === "SUPERUSER" ? (
              <Datagrid
                bulkActionButtons={false}
                sort={{ field: "id", order: "DESC" }}
                sx={commonDatagridStyling}
                rowClick={(id) => `${id}`}
                {...practitionersInInstitution}
              >
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="email" />
                <DateField source="lastLogin" emptyText="-" fullWidth showTime />
                <RemoveFromInstitutionButton />
              </Datagrid>
            ) : (
              <div />
            )}
          </List>
        </Box>
        {role === "SUPERUSER" ? (
          <Box>
            <Typography
              sx={{
                padding: 2,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              All Practitioners
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <MuiTextField
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                variant="filled"
                label="Search"
              />
               <MuiButton
                variant="outlined"
                onClick={handleFilter}
                sx={{ ml: 1 }}
              >
                Search
              </MuiButton>
              <MuiButton
                variant="outlined"
                onClick={handleClearFilters}
                sx={{ ml: 1 }}
                startIcon={<ClearIcon />}
              >
                Clear
              </MuiButton>
            </Box>
            <Datagrid
              bulkActionButtons={false}
              sx={commonDatagridStyling}
              sort={{ field: "id", order: "DESC" }}
              {...allPractitioners}
            >
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="email" />
              <AssignToInstitutionButton
                institutionId={institutionId}
                resource={`institutions/${institutionId}/practitioners`}
              />
            </Datagrid>
            <Pagination {...pagination} total={allPractitioners.total} />
          </Box>
        ) : null}
      </Box>
    </InstitutionEdit>
  );
};

export default InstitutionPractitionersList;