import { Practitioner } from "./types";

export const transformDataBeforeRequest = (data: Practitioner) => {
  const dataToTransform = data;

  dataToTransform.id = undefined;
  dataToTransform.email = undefined;
  dataToTransform.lastLogin = undefined;

  return dataToTransform;
};