import { Language } from "src/activities/types";

export enum PolicyStatus {
  DRAFT = "DRAFT",
  RETIRED = "RETIRED",
  ACTIVE = "ACTIVE"
}

enum PolicyType {
  GDPR = "GDPR",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  APP_USAGE = "APP_USAGE",
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
  RESEARCH = "RESEARCH"
}

enum PolicyRole {
  COMMON = "COMMON",
  MHP = "MHP",
  PAT = "PAT"
}

interface Translation {
  locale: Language;
  text: string;
  title: string;
}

export interface Policy {
  id?: string;
  type: PolicyType;
  version: number;
  createdAt: Date;
  updatedAt: Date;
  isOptional: boolean;
  status: PolicyStatus;
  role: PolicyRole;
  institutionId?: string;
  translations: Array<Translation>
}
