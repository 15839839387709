import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button as MuiButton,
  TextField as MuiTextField,
  Typography} from "@mui/material";
import { FC, useContext, useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
  Title,
  useFilterState,
  useGetList,
  usePaginationState,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { Statistics } from "src/common/statistics";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import InstitutionEdit from "..";
import { commonDatagridStyling } from "../commonStyling";
import { AssignToInstitutionButton } from "./assignToInstitutionButton";
import { RemoveFromInstitutionButton } from "./removeFromInstitutionButton";

const InstitutionPatientsList: FC = () => {
  const { role } = useContext(AppRoleContext);
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";
  const pagination = usePaginationState({ perPage: 5 });
  const { filter, setFilter } = useFilterState({});
  const [searchText, setSearchText] = useState("");
  
  const allPatients = useGetList("users/patients", { pagination, filter });
  const patientsInInstitution = useGetList(
    `institutions/${institutionId}/patients`,
    { pagination: { perPage: 1, page: 1 }}
  );

  const handleFilter = () => {
    setFilter(searchText);
  };

  const handleClearFilters = () => {
    setFilter('');
    setSearchText("");
  };

  return (
    <InstitutionEdit>
      <Box display="flex" flexDirection="column" gap="50px">
        <Title title="Patients" />
        <List
          pagination={false}
          aside={
            <Statistics resource={`institutions/${institutionId}/patients`} />
          }
          title={" "}
          actions={false}
          exporter={false}
        >
          {role === "SUPERUSER" ? (
            <Datagrid
              rowClick={(id) => `${id}`}
              bulkActionButtons={false}
              {...patientsInInstitution}
            >
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="email" />
              <DateField source="lastLogin" emptyText="-" fullWidth showTime />
              <RemoveFromInstitutionButton />
            </Datagrid>
          ) : null}
        </List>
        {role === "SUPERUSER" ? (
          <Box>
            <Typography
              sx={{
                padding: 2,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              All patients
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <MuiTextField
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                variant="filled"
                label="Search"
              />
               <MuiButton
                variant="outlined"
                onClick={handleFilter}
                sx={{ ml: 1 }}
              >
                Search
              </MuiButton>
              <MuiButton
                variant="outlined"
                onClick={handleClearFilters}
                sx={{ ml: 1 }}
                startIcon={<ClearIcon />}
              >
                Clear
              </MuiButton>
            </Box>
            <Datagrid
              bulkActionButtons={false}
              sx={commonDatagridStyling}
              sort={{ field: "id", order: "DESC" }}
              {...allPatients}
            >
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="email" />
              <AssignToInstitutionButton
                institutionId={institutionId}
                resource={`institutions/${institutionId}/patients`}
              />
            </Datagrid>
            <Pagination {...pagination} total={allPatients.total} />
          </Box>
        ) : null}
      </Box>
    </InstitutionEdit>
  );
};

export default InstitutionPatientsList;
