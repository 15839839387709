import { FC } from "react";
import { useWatch } from "react-hook-form";
import { ActivityItemTypeChoices } from "src/activities/selectChoices/activityItemTypeChoices";
import { ActivityItems } from "src/activities/subforms/activityItems";
import { Journal } from "src/journals/types";

export const JournalIntroductionItems: FC = () => {
  const addIntroduction = useWatch<Journal>({
    name: "addIntroduction",
  }) as boolean | undefined;

  if (!addIntroduction) {
    return (
      <p>
        To enable Introduction Items you need to toggle Add Introduction in the
        Introduction Overview tab.
      </p>
    );
  }
  return (
    <>
      <ActivityItems
        source="introduction.items"
        choices={ActivityItemTypeChoices}
        isRequired={false}
      />
    </>
  );
};
