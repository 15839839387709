export const PolicyType = [
  { id: "GDPR", name: "GDPR" },
  { id: "PRIVACY_POLICY", name: "Privacy Policy" },
  { id: "APP_USAGE", name: "Application Usage" },
  { id: "TERMS_AND_CONDITIONS", name: "Terms and Conditions" },
  { id: "RESEARCH", name: "Research" }
];

export const PolicyStatus = [
  { id: "DRAFT", name: "DRAFT" },
  { id: "ACTIVE", name: "ACTIVE" },
  { id: "RETIRED", name: "RETIRED" }
];


export const PolicyRole = [
  { id: "COMMON", name: "Common" },
  { id: "MHP", name: "MHP" },
  { id: "PAT", name: "PAT" },
];
