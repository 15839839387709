import { useContext } from 'react';
import { Datagrid, DateField, List, Pagination,ReferenceField, TextField } from 'react-admin';
import { useParams } from "react-router-dom";
import { AppRoleContext } from 'src/context/AppRoleContext/AppRoleContext';

const PolicyShow = () => {
    const { role } = useContext(AppRoleContext);
    const { id } = useParams();

    return (
        <List
            resource={`policies/${id}/users`}
            perPage={10}
            pagination={<Pagination />}
            sort={{ field: 'consentDate', order: 'DESC' }}
        >
            <Datagrid bulkActionButtons={false}>
                <>
                    <ReferenceField
                        source="id"
                        reference="users/patients"
                    >
                        <TextField source="firstName" /> <TextField source="lastName" display={role === "SUPERUSER" ? "inherit" : "none"} />
                    </ReferenceField>
                    
                    <ReferenceField
                        source="id"
                        reference="users/practitioners"
                    >
                        <TextField source="firstName" /> <TextField source="lastName" display={role === "SUPERUSER" ? "inherit" : "none"} />
                    </ReferenceField>
                </>
                <DateField showTime source="consentDate" />
                <DateField showTime source="rejectedAt" />
                <DateField showTime source="revokedAt" />
            </Datagrid>
        </List>
    );
};

export default PolicyShow;