import { FC, useContext } from "react";
import {
  CreateButton,
  Datagrid,
  List,
  TextField,
  TopToolbar,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { Statistics } from "src/common/statistics";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import InstitutionEdit from "..";

interface ListActionsProps {
  institutionId: string;
}

const ListActions: FC<ListActionsProps> = ({ institutionId }) => (
  <TopToolbar>
    <CreateButton resource={`/institutions/${institutionId}/teams`} />
  </TopToolbar>
);

const InstitutionTeamsList: FC = () => {
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";
  const { role } = useContext(AppRoleContext);

  return (
    <InstitutionEdit>
      <List
        title="Teams"
        pagination={false}
        resource={`institutions/${institutionId}/teams`}
        actions={<ListActions institutionId={institutionId} />}
        aside={<Statistics onlyTotal />}
        hasCreate
      >
        <Datagrid
          rowClick={(id) => (role === "SUPERUSER" ? `${id}` : false)}
          bulkActionButtons={false}
        >
          <TextField source="name" />
        </Datagrid>
      </List>
    </InstitutionEdit>
  );
};

export default InstitutionTeamsList;
