import { FC } from "react";
import { BooleanInput } from "react-admin";
import { useWatch } from "react-hook-form";

import { Journal, JournalIntroduction } from "../../types";
import { IntroductionForm } from "./introductionForm";

const JournalIntroductionForm: FC = () => {
  const introductionTitle = useWatch<Journal>({ name: "introduction.title" }) as
    | JournalIntroduction
    | undefined;

  return (
    <>
      <BooleanInput
        source="addIntroduction"
        defaultValue={!!introductionTitle}
      />
      <IntroductionForm />
    </>
  );
};

export default JournalIntroductionForm;
