import { Box, FormHelperText, Typography } from "@mui/material";
import { FC } from "react";
import {
  ArrayInput,
  BooleanInput,
  FileField,
  FileInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  minValue,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Validator,
} from "react-admin";
import { CustomNumberInput } from "src/components/CustomNumberInput";
import { FormItemButtons } from "src/components/FormItemButtons";

import {
  commonAnswerOptionsListStyling,
  commonAnswerOptionsStyling,
  commonItemsStyling,
} from "../commonStyling";
import { ActivityItemTypeChoices } from "../selectChoices/activityItemTypeChoices";
import { PatientRecordChoices } from "../selectChoices/patientRecordChoices";
import { Activity, ActivityItem, ActivityItemType } from "../types";
import { answerOptionValueRequired } from "../validateForm";

export const ActivityItems: FC<{
  choices?: Array<{ id: string; name: string }>;
  source?: string;
  isRequired?: boolean;
  additionalValidators?: Validator[];
}> = ({
  choices = ActivityItemTypeChoices,
  source = "items",
  isRequired = true,
  additionalValidators = [],
}) => (
  <>
    <ArrayInput
      source={source}
      validate={
        isRequired
          ? [...additionalValidators, required()]
          : [...additionalValidators]
      }
    >
      <SimpleFormIterator
        sx={commonItemsStyling}
        inline
        getItemLabel={(index) => `#${index + 1}`}
        reOrderButtons={<FormItemButtons />}
        className="items-list"
      >
        <SelectInput
          source="activityItemType"
          validate={required()}
          fullWidth
          choices={choices}
        />
        <FormDataConsumer<Activity>>
          {({
            scopedFormData,
            getSource,
          }: {
            getSource?: (string) => string;
            scopedFormData?: ActivityItem;
          }) => {
            if (
              (scopedFormData?.activityItemType ===
                ActivityItemType.PRACTITIONER_INPUT_IMAGE ||
                scopedFormData?.activityItemType ===
                  ActivityItemType.PRACTITIONER_INPUT_AUDIO) &&
              getSource
            ) {
              return (
                <>
                  <TextInput
                    source={getSource("displayValue")}
                    defaultValue="none"
                    multiline
                    helperText={false}
                    maxRows={8}
                    fullWidth
                  />
                  <CustomNumberInput
                    defaultValue={1}
                    source={getSource("version")}
                    validate={[minValue(1), required()]}
                    style={{ flex: 1 }}
                  />
                </>
              );
            }

            if (
              scopedFormData?.activityItemType !==
                ActivityItemType.PAGE_BREAK &&
              getSource
            ) {
              return (
                <>
                  {scopedFormData?.activityItemType ===
                    "PATIENT_RESPONSE_SMILEY_SCALE" && (
                    <FormHelperText>
                      Smiley scale is represented by values:
                      <br />
                      - Very good
                      <br />
                      - Good
                      <br />
                      - Mediocre
                      <br />
                      - Poor
                      <br />
                      - Very bad
                      <br />- Do not know
                    </FormHelperText>
                  )}
                  <TextInput
                    source={getSource("displayValue")}
                    validate={required()}
                    defaultValue="none"
                    multiline
                    helperText={false}
                    maxRows={8}
                    fullWidth
                  />
                  <CustomNumberInput
                    defaultValue={1}
                    source={getSource("version")}
                    validate={[minValue(1), required()]}
                    style={{ flex: 1 }}
                  />
                </>
              );
            }

            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer<Activity>>
          {({
            scopedFormData,
            getSource,
          }: {
            getSource?: (string) => string;
            scopedFormData?: ActivityItem;
          }) => {
            if (
              scopedFormData?.activityItemType.includes("PATIENT_RESPONSE") &&
              getSource
            ) {
              return (
                <>
                  <TextInput
                    source={getSource("semanticIdentifier")}
                    helperText={false}
                    style={{ flex: 2 }}
                    validate={[
                      ...(scopedFormData?.activityItemType ===
                        ActivityItemType.PATIENT_RESPONSE_LIKERT_SCALE ||
                      scopedFormData?.activityItemType ===
                        ActivityItemType.PATIENT_RESPONSE_SMILEY_SCALE
                        ? [required()]
                        : []),
                    ]}
                  />
                  <TextInput
                    source={getSource("initialValue")}
                    multiline
                    helperText={false}
                    fullWidth
                  />

                  <BooleanInput
                    source={getSource("required")}
                    helperText={false}
                    defaultValue={true}
                    disabled={
                      scopedFormData?.activityItemType ===
                      ActivityItemType.PATIENT_RESPONSE_SORTING
                    }
                  />
                </>
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer<Activity>>
          {({
            scopedFormData,
            getSource,
          }: {
            getSource?: (string) => string;
            scopedFormData?: ActivityItem;
          }) => {
            if (
              scopedFormData?.activityItemType ===
                ActivityItemType.PATIENT_RESPONSE_PATIENT_RECORD &&
              getSource
            ) {
              return (
                <>
                  <SelectInput
                    source={getSource("recordType")}
                    fullWidth
                    defaultValue={undefined}
                    choices={PatientRecordChoices}
                    validate={[
                      ...(scopedFormData?.activityItemType ===
                      ActivityItemType.PATIENT_RESPONSE_PATIENT_RECORD
                        ? [required()]
                        : []),
                    ]}
                  />
                </>
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer<Activity>>
          {({
            scopedFormData,
            getSource,
          }: {
            getSource?: (string) => string;
            scopedFormData?: ActivityItem;
          }) => {
            if (
              scopedFormData?.activityItemType ===
                ActivityItemType.PRACTITIONER_INPUT_IMAGE &&
              getSource
            ) {
              return (
                <Box width={"100%"}>
                  <ImageInput
                    source={getSource("picture")}
                    label="Activity picture"
                    accept="image/jpeg, image/png, image/webp, image/jpg"
                    multiple={false}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                  <TextInput
                    source={getSource("imageDescription")}
                    helperText={false}
                    fullWidth
                  />
                  <TextInput
                    source={getSource("alt")}
                    validate={required()}
                    helperText={false}
                    fullWidth
                  />
                </Box>
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer<Activity>>
          {({
            scopedFormData,
            getSource,
          }: {
            getSource?: (string) => string;
            scopedFormData?: ActivityItem;
          }) => {
            if (
              scopedFormData?.activityItemType ===
                ActivityItemType.PRACTITIONER_INPUT_AUDIO &&
              getSource
            ) {
              return (
                <Box width={"100%"}>
                  <Typography>Not implemented</Typography>
                  <FileInput
                    source={getSource("asset")}
                    label="Activity audio"
                    accept="audio/*"
                    multiple={false}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </Box>
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <FormDataConsumer<Activity>>
          {({
            scopedFormData,
            getSource,
          }: {
            getSource?: (string) => string;
            scopedFormData?: ActivityItem;
          }) => {
            if (
              getSource &&
              [
                ActivityItemType.PATIENT_RESPONSE_LIKERT_SCALE,
                ActivityItemType.PATIENT_RESPONSE_LINEAR_SCALE,
                ActivityItemType.PATIENT_RESPONSE_SINGLE_CHOICE,
                ActivityItemType.PATIENT_RESPONSE_MULTIPLE_CHOICE,
                ActivityItemType.PATIENT_RESPONSE_SORTING,
              ].includes(scopedFormData?.activityItemType)
            ) {
              return (
                <ArrayInput
                  sx={commonAnswerOptionsListStyling}
                  required
                  source={getSource("answerOptions")}
                >
                  <SimpleFormIterator
                    sx={commonAnswerOptionsStyling}
                    getItemLabel={(index) => `${index + 1}.`}
                    inline
                  >
                    <TextInput
                      source="name"
                      validate={required()}
                      helperText={false}
                      style={{ flex: 1 }}
                    />
                    <TextInput
                      source="value"
                      validate={answerOptionValueRequired}
                      helperText={false}
                      required={
                        scopedFormData?.activityItemType ===
                        ActivityItemType.PATIENT_RESPONSE_LIKERT_SCALE
                      }
                      style={{ flex: 1 }}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }
            return null;
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  </>
);
