import { Datagrid, EditButton, List, TextField } from "react-admin";
import { Statistics } from "src/common/statistics";

const ActivityList = () => {
  return (
    <List aside={<Statistics />}>
      <Datagrid rowClick='show' bulkActionButtons={false}>
        <TextField source="title" />
        <TextField source="activityType" />
        <TextField source="status" />
        <EditButton label="Edit"/>
      </Datagrid>
    </List>
  );
}

export default ActivityList;
