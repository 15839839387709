import { FC } from "react";
import {
  required,
  SelectInput} from "react-admin";

import { PathwayTimeRelationChoices } from "../choices";
import { PathForm } from "./pathForm";

export const MainPath: FC = () => (
  <>
    <SelectInput
      source="mainPath.timeRelation"
      validate={required()}
      fullWidth
      choices={PathwayTimeRelationChoices}
    />
    <PathForm source="mainPath.activities" />
  </>
);
