import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from 'react';
import {
  BooleanInput,
  Button as ReactAdminButton,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  Labeled,
  List,
  maxLength,
  ReferenceField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { useMutation } from 'react-query';
import { useLocation, useParams } from "react-router-dom";
import { CustomDataProvider } from 'src/auth';

import { useMutationErrorNotify } from "../common/handleMutationError";
import { LanguageChoices } from "./languageChoices";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";

const UserEditToolbar = (props) => { 
  const notify = useNotify();
  const { id } = useParams();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [reasonError, setReasonError] = useState('');
  const dataProvider = useDataProvider<CustomDataProvider>();
  const redirect = useRedirect();

  const { mutateAsync: forceRemoveAccountAsync, isLoading: isForceRemovingAccount  } = useMutation((reason: string) =>
    dataProvider.forceRemoveAccount(id, reason, 'MHP')
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value); 
  };

  const handleDeleteClick = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);
  const handleDeleteConfirm = async (reason: string) => {
    try {
      if (!reason.trim()) {
        setReasonError('Reason for deletion cannot be empty.');
        return;
      }
  
      await forceRemoveAccountAsync(reason);

      redirect('/users/practitioners');
      notify('User removed', {type: 'success' });
      setDeleteDialogOpen(false);
    } catch (error) {

      notify('Error occurred during deletion', {type: 'error' });
      setDeleteDialogOpen(false);
    }
  };
  
  return (
    <>
      <Toolbar {...props}>
        <SaveButton />
        <ReactAdminButton label="Delete" onClick={handleDeleteClick} startIcon={<DeleteOutlineIcon />} style={{
          backgroundColor: 'red',
          color: 'white',
          fontSize: '0.875rem',
          borderRadius: '4px',
          marginLeft: '8px',
          padding: '8px 16px'
        }} /> 
      </Toolbar>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {`Are you sure you want to remove account?`}
        </DialogTitle>
        <DialogContent>
        <div>
          <div style={{ color: 'orange', marginTop: '8px', marginBottom: '8px' }}>
            Warning: It will remove the user account, and this action cannot be reversed.
          </div>
          <div>
            <label htmlFor="reason">Reason for deletion:</label>
            <input
                type="text"
                id="reason"
                value={inputValue}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
            />
          </div>
        </div>
        {reasonError && (
          <Typography variant="body2" color="error">
            {reasonError}
          </Typography>
        )}
        </DialogContent>
        <DialogActions>
          <ReactAdminButton
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteDialogClose();
            }}
          />
          <ReactAdminButton
            label="Confirm"
            disabled={isForceRemovingAccount}
            onClick={(e) => {
              e.stopPropagation();
              void handleDeleteConfirm(inputValue);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const PractitionersEdit = () => {
  const notify = useMutationErrorNotify();
  const { pathname } = useLocation();
  const practitionerId = pathname.split("/")[3] || "";

  return (
    <Box display="flex" flexDirection="column" gap="50px">
      <Edit
        title="Practitioners"
        mutationMode="pessimistic"
        transform={transformDataBeforeRequest}
        mutationOptions={{
          onError: notify,
        }}
      >
        <SimpleForm toolbar={<UserEditToolbar />}>
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            gap="3%"
          >
            <ImageField source="picture.src" />
            <Labeled>
              <TextField source="email" />
            </Labeled>
            <Labeled>
              <DateField source="lastLogin" emptyText="-" fullWidth showTime />
            </Labeled>
            <TextInput source="firstName" validate={required()} fullWidth />
            <TextInput source="lastName" validate={required()} fullWidth />
            <TextInput
              source="profession"
              validate={maxLength(200)}
              fullWidth
            />
            <TextInput
              source="academicTitle"
              validate={maxLength(200)}
              fullWidth
            />
            <SelectInput
              source="language"
              choices={LanguageChoices}
              fullWidth
            />
            <BooleanInput
              source="isTesting"
            />
          </Box>
        </SimpleForm>
      </Edit>

      <Box>
        <Typography>Practitioner institutions</Typography>
        <List
          title=" "
          pagination={false}
          actions={false}
          resource={`users/practitioners/${practitionerId}/institutions`}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={(id) => `institutions/${id}`}
          >
            <TextField source="name" />
          </Datagrid>
        </List>
      </Box>

      <Box paddingBottom="50px">
        <Typography>Practitioner PATs</Typography>
        <List
          title=" "
          pagination={false}
          actions={false}
          resource={`users/practitioners/${practitionerId}/patients`}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="firstName" />
            <TextField source="lastName" />
          </Datagrid>
        </List>
      </Box>

      <Box paddingBottom="50px">
        <Typography>Consents</Typography>
        <List
          title=" "
          pagination={false}
          actions={false}
          resource={`users/${practitionerId}/consents`}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              label="Consent"
              source="policyId"
              reference="policies"
              emptyText="-"
            />
            <DateField source="consentAt" />
            <DateField source="revokedAt" emptyText="-" />
          </Datagrid>
        </List>
      </Box>
    </Box>
  );
};

export default PractitionersEdit;
