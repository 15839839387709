import CircleIcon from "@mui/icons-material/Circle";
import {
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemIcon
} from "@mui/material";
import { FC, useMemo } from "react";
import { required, SelectInput, TextInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { colorChoices } from "src/activities/selectChoices/colorChoices";
import {
  Activity,
  ActivityItem,
  ActivityScore,
  AnswerOption,
  EvaluationConfig
} from "src/activities/types";
import { CustomNumberInput } from "src/components/CustomNumberInput";

export const EvaluationForm: FC = () => {
  const scores = useWatch<Activity>({ name: "scores" }) as
    | Array<ActivityScore>
    | undefined;

  const items = useWatch<Activity>({ name: "items" }) as
    | Array<ActivityItem>
    | undefined;

  const evaluation = useWatch<Activity>({ name: "evaluation" }) as
    | EvaluationConfig
    | undefined;

  const isEvaluationEnabled = useWatch<Activity>({
    name: "isEvaluationEnabled"
  }) as boolean | undefined;

  const mainScore = useMemo(
    () => scores?.find((score) => score?.isMainScore),
    [scores]
  );

  const questions = useMemo(
    () =>
      mainScore?.calculationItems
        .map((item) =>
          items.find((question) => question.semanticIdentifier === item)
        )
        .filter((q) => !!q) ?? [],
    [items, mainScore?.calculationItems]
  );

  const answerOptions = useMemo(
    () =>
      questions
        .flatMap((question) => question?.answerOptions ?? [])
        .reduce((prev, curr) => {
          if (prev.find((question) => question.value === curr.value)) {
            return prev;
          }

          return [...prev, curr];
        }, [] as Array<AnswerOption>),
    [questions]
  );

  if (!isEvaluationEnabled || !mainScore) {
    return null;
  }

  return (
    <>
      <TextInput
        source="evaluation.title"
        label="Title"
        validate={required()}
        fullWidth
      />
      <TextInput
        source="evaluation.description"
        label="Description"
        validate={required()}
        multiline
        maxRows={4}
        fullWidth
      />
      <FormControl>
        <FormLabel htmlFor="scoreEvaluations" required>
          SCORE EVALUATION CONFIG
        </FormLabel>
        <List id="scoreEvaluations">
          {mainScore.scoreInterpretations.map((interpretation, index) => (
            <ListItem key={`scoreEvaluations.${index}`} sx={{ gap: "3%" }}>
              <TextInput
                source={`evaluation.scoreEvaluations.${index}.label`}
                defaultValue={interpretation.label}
                disabled
                sx={{ flex: 1 }}
              />
              <CustomNumberInput
                source={`evaluation.scoreEvaluations.${index}.min`}
                defaultValue={interpretation.min}
                disabled
                sx={{ flex: 1 }}
              />
              <CustomNumberInput
                source={`evaluation.scoreEvaluations.${index}.max`}
                defaultValue={interpretation.max}
                disabled
                sx={{ flex: 1 }}
              />
              <SelectInput
                source={`evaluation.scoreEvaluations.${index}.color`}
                choices={colorChoices}
                validate={required()}
                sx={{ flex: 2 }}
              />
              <ListItemIcon>
                <CircleIcon
                  style={{
                    color:
                      evaluation?.scoreEvaluations?.[index]?.color ??
                      "transparent"
                  }}
                  fontSize="large"
                />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="questionEvaluations" required>
          QUESTIONS EVALUATION CONFIG
        </FormLabel>
        <List id="questionEvaluations">
          {answerOptions.map((question, index) => (
            <ListItem key={`questionEvaluations.${index}`} sx={{ gap: "3%" }}>
              <TextInput
                source={`evaluation.questionEvaluations.${index}.name`}
                defaultValue={question.name}
                disabled
                sx={{ flex: 1 }}
              />
              <CustomNumberInput
                source={`evaluation.questionEvaluations.${index}.value`}
                defaultValue={question.value ?? 0}
                disabled
                sx={{ flex: 1 }}
              />

              <SelectInput
                source={`evaluation.questionEvaluations.${index}.color`}
                choices={colorChoices}
                validate={required()}
                sx={{ flex: 2 }}
              />
              <ListItemIcon>
                <CircleIcon
                  style={{
                    color:
                      evaluation?.questionEvaluations?.[index]?.color ??
                      "transparent"
                  }}
                  fontSize="large"
                />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="questions" required>
          QUESTIONS CONFIG
        </FormLabel>
        <List id="questions">
          {questions.map((question, index) => (
            <ListItem key={`evaluation.${index}`} sx={{ gap: "3%" }}>
              <TextInput
                source={`evaluation.questions.${index}.displayValue`}
                defaultValue={question.displayValue}
                disabled
                sx={{ flex: 1 }}
              />
              <TextInput
                source={`evaluation.questions.${index}.semanticIdentifier`}
                defaultValue={question.semanticIdentifier}
                disabled
                sx={{ flex: 1 }}
              />
              <TextInput
                source={`evaluation.questions.${index}.shortName`}
                validate={required()}
                sx={{ flex: 1 }}
              />
            </ListItem>
          ))}
        </List>
      </FormControl>
    </>
  );
};
