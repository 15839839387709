import { Box, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { Datagrid, TextField, Title, useGetList } from "react-admin";
import { useParams } from "react-router-dom";

import { Practitioner } from "../../../institutions/types";
import { commonDatagridStyling } from "../commonStyling";
import { AssignToPractitionerButton } from "./assignToPractitionerButton";
import { RemoveFromPractitionerButton } from "./removeFromPractitionerButton";

const InstitutionPractitionerEdit: FC = () => {
  const { institutionId, practitionerId } = useParams();

  const allPatientsInInstitution = useGetList<Practitioner>(
    `institutions/${institutionId}/patients`
  );
  const practitionersPatients = useGetList<Practitioner>(
    `institutions/${institutionId}/practitioners/${practitionerId}/patients`
  );

  const notPractitionersPatients = useMemo(() => {
    if (!allPatientsInInstitution.data || !practitionersPatients.data) return;

    const practitionersPatientsIds = practitionersPatients.data.map(
      (practitioner) => practitioner.id
    );

    return allPatientsInInstitution.data.filter(
      (practitioner) => !practitionersPatientsIds.includes(practitioner.id)
    );
  }, [allPatientsInInstitution, practitionersPatients]);

  return (
    <Box display="flex" flexDirection="column" paddingTop="50px" gap="50px">
      <Title title="Practitioners" />
      <Box>
        <Typography>{"Practitioner's patients"}</Typography>
        <Datagrid
          data={practitionersPatients.data}
          total={practitionersPatients.total}
          isLoading={practitionersPatients.isLoading}
          bulkActionButtons={false}
          sort={{ field: "id", order: "DESC" }}
          sx={commonDatagridStyling}
        >
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <RemoveFromPractitionerButton />
        </Datagrid>
      </Box>

      <Box>
        <Typography>{"Not practitioner's patients"}</Typography>
        <Datagrid
          data={notPractitionersPatients}
          total={notPractitionersPatients?.length}
          isLoading={
            practitionersPatients.isLoading ||
            allPatientsInInstitution.isLoading
          }
          bulkActionButtons={false}
          sort={{ field: "id", order: "DESC" }}
          sx={commonDatagridStyling}
        >
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <AssignToPractitionerButton />
        </Datagrid>
      </Box>
    </Box>
  );
};

export default InstitutionPractitionerEdit;
