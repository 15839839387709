import dayjs from "dayjs";
import { useMemo, useRef } from "react";
import { useGetList, UseGetListHookValue } from "react-admin";

interface IUseInfoTable {
  isLoading: boolean;
  lastMonth: string;
  tableConfig: Array<{
    month: string;
    values: Array<{ value: string; change: number }>;
  }>;
}

const useMonthData = (
  resource: string,
  monthNumber: number,
  today: dayjs.Dayjs,
  activityId?: string,
  institutionId?: string,
  practitionerId?: string,
) => {
  const label = today
    .subtract(monthNumber, "month")
    .startOf("month")
    .format("MMM YYYY");
  const data = useGetList<undefined>(resource, {
    filter: {
      isTesting: false,
      from: today.subtract(monthNumber, "month").startOf("month").toISOString(),
      to: today.subtract(monthNumber, "month").endOf("month").toISOString(),
      activityId,
      institutionId,
      practitionerId
    },
    pagination: { perPage: 1, page: 1 },
  });

  return {
    data,
    label,
  };
};

const useTableData = (
  monthA: {
    data: UseGetListHookValue<undefined>;
    label: string;
  },
  monthB: {
    data: UseGetListHookValue<undefined>;
    label: string;
  },
  valueType: "raw" | "percent"
): {
  month: string;
  values: Array<{ value: string; change: number }>;
} => {
  const calculateChange = (current: number, previous: number) => {
    if (previous === 0) {
      return current > 0 ? 1 : 0;
    }
    return (current - previous) / previous;
  };

  const change = calculateChange(monthA.data.total ?? 0, monthB.data.total ?? 0);

  return {
    month: monthA.label,
    values: [
      {
        value:
          valueType === "raw"
            ? `${monthA.data.total ?? 0}`
            : `${(monthA.data.total ?? 0) * 100}%`,
        change,
      },
    ],
  };
};

export const useInfoTable = (
  resource: string,
  valueType: "raw" | "percent",
  {
    activityId,
    institutionId,
    practitionerId
  }: { activityId?: string; institutionId?: string, practitionerId?: string } = {}
): IUseInfoTable => {
  const today = useRef(dayjs().startOf("d"));

  const { total: lastMonth, isLoading: lastMonthIsLoading } = useGetList(
    resource,
    {
      filter: {
        isTesting: false,
        from: today.current.subtract(30, "d").toISOString(),
        to: today.current.toISOString(),
        activityId,
        institutionId,
        practitionerId
      },
      pagination: { perPage: 1, page: 1 },
    }
  );

  const month1 = useMonthData(
    resource,
    1,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month2 = useMonthData(
    resource,
    2,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month3 = useMonthData(
    resource,
    3,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month4 = useMonthData(
    resource,
    4,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month5 = useMonthData(
    resource,
    5,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month6 = useMonthData(
    resource,
    6,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month7 = useMonthData(
    resource,
    7,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month8 = useMonthData(
    resource,
    8,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month9 = useMonthData(
    resource,
    9,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month10 = useMonthData(
    resource,
    10,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month11 = useMonthData(
    resource,
    11,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month12 = useMonthData(
    resource,
    12,
    today.current,
    activityId,
    institutionId, practitionerId
  );
  const month13 = useMonthData(
    resource,
    13,
    today.current,
    activityId,
    institutionId, practitionerId
  );

  const isLoading = useMemo(
    () =>
      lastMonthIsLoading ||
      month1.data.isLoading ||
      month2.data.isLoading ||
      month3.data.isLoading ||
      month4.data.isLoading ||
      month5.data.isLoading ||
      month6.data.isLoading ||
      month7.data.isLoading ||
      month8.data.isLoading ||
      month9.data.isLoading ||
      month10.data.isLoading ||
      month11.data.isLoading ||
      month12.data.isLoading ||
      month13.data.isLoading,
    [
      lastMonthIsLoading,
      month1.data.isLoading,
      month10.data.isLoading,
      month11.data.isLoading,
      month12.data.isLoading,
      month13.data.isLoading,
      month2.data.isLoading,
      month3.data.isLoading,
      month4.data.isLoading,
      month5.data.isLoading,
      month6.data.isLoading,
      month7.data.isLoading,
      month8.data.isLoading,
      month9.data.isLoading,
    ]
  );

  return {
    isLoading,
    lastMonth:
      valueType === "raw" ? `${lastMonth ?? 0}` : `${(lastMonth ?? 0) * 100}%`,
    tableConfig: [
      useTableData(month1, month2, valueType),
      useTableData(month2, month3, valueType),
      useTableData(month3, month4, valueType),
      useTableData(month4, month5, valueType),
      useTableData(month5, month6, valueType),
      useTableData(month6, month7, valueType),
      useTableData(month7, month8, valueType),
      useTableData(month8, month9, valueType),
      useTableData(month9, month10, valueType),
      useTableData(month10, month11, valueType),
      useTableData(month11, month12, valueType),
      useTableData(month12, month13, valueType),
    ],
  };
};
