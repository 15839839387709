
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

import AuditTrailsList from './auditTrailsList';

export default {
  list: AuditTrailsList,
  icon: ViewTimelineIcon,
  options: { label: "Audit Trails" },
};
