import { FC, useMemo } from "react";
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput
} from "react-admin";
import {
  commonAnswerOptionsListStyling,
  commonAnswerOptionsStyling
} from "src/activities/commonStyling";
import { ActivityScore } from "src/activities/types";
import { CustomNumberInput } from "src/components/CustomNumberInput";

interface ScoreInterpretationsProps {
  scores: Array<ActivityScore>;
  getSource: (name: string) => string;
  hasEvaluation?: boolean;
}

const getIndexFromSource = (source: string): number | undefined => {
  const index = source.split(".").find((key) => !isNaN(Number(key)));

  return typeof index === "string" ? parseInt(index) : undefined;
};

export const ScoreInterpretations: FC<ScoreInterpretationsProps> = ({
  getSource,
  scores,
  hasEvaluation
}) => {
  const index = useMemo(() => getIndexFromSource(getSource("")), [getSource]);
  const isArrayDisabled = useMemo(
    () => scores[index]?.isMainScore && hasEvaluation,
    [scores, index, hasEvaluation]
  );
  return (
    <ArrayInput
      sx={commonAnswerOptionsListStyling}
      source={getSource("scoreInterpretations")}
      disabled={isArrayDisabled}
    >
      <SimpleFormIterator
        sx={commonAnswerOptionsStyling}
        getItemLabel={(index) => `${index + 1}.`}
        inline
      >
        <TextInput
          source={"label"}
          validate={required()}
          helperText={false}
          style={{ flex: 1 }}
        />
        <CustomNumberInput
          source={"min"}
          validate={required()}
          helperText={false}
          style={{ flex: 1 }}
        />
        <CustomNumberInput
          source={"max"}
          validate={required()}
          helperText={false}
          style={{ flex: 1 }}
        />
        <TextInput
          source={"description"}
          helperText={false}
          multiline
          maxRows={3}
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
