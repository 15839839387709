import { useGetList } from "react-admin";

export const useRatingChart = (activityId: string, institutionId?: string) => {
  const { total = 0, isLoading } = useGetList("statistics/activity-avg-rating", {
    filter: { activityId, institutionId },
  });

  return {
    avg: total,
    isLoading,
  };
};
