import { Box } from "@mui/material";
import { FC, useContext } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  Title,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { Statistics } from "src/common/statistics";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import InstitutionEdit from "..";
import { commonDatagridStyling } from "../commonStyling";

const PatientsOnboardingList: FC = () => {
  const { role } = useContext(AppRoleContext);
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";

  return (
    <InstitutionEdit>
    <Box display="flex" flexDirection="column" gap="50px">
      <Title title="Patients onboarding" />
      <Box>
        <List
          resource={`institutions/${institutionId}/invitations`}
          pagination={false}
          title={" "}
          aside={<Statistics />}
          exporter={false}
        >
          {role === "SUPERUSER" ? (
            <Datagrid
              bulkActionButtons={false}
              sort={{ field: "email", order: "DESC" }}
              sx={commonDatagridStyling}
            >
              <TextField source="email" />
              <DateField
                source="birthdate"
                label="Birthdate"
              />
              <DateField source="createdAt" label="Invited At" showTime />
              <DateField
                source="lastSendingDate"
                label="Last invitation resend"
                showTime
              />
              <ReferenceField
                label="Added by"
                source="addedBy"
                reference="users/practitioners"
                emptyText="-"
              >
                <TextField source="firstName" /> <TextField source="lastName" />
              </ReferenceField>
              <BooleanField source="isActive" />
            </Datagrid>
          ) : (
            <div />
          )}
        </List>
      </Box>
    </Box></InstitutionEdit>
  );
};

export default PatientsOnboardingList;
