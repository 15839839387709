import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useGetOne,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { CustomDataProvider } from "../../../auth";
import { Patient, Practitioner } from "../../types";

export const AssignToPractitionerButton: FC = () => {
  const [open, setOpen] = useState(false);
  const { institutionId, practitionerId } = useParams();
  const { id, firstName, lastName } = useRecordContext<Patient>();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const { mutateAsync } = useMutation(() =>
    dataProvider.assignPatientToPractitioner(institutionId, practitionerId, id)
  );

  const practitioner = useGetOne<Practitioner>(
    `users/practitioners`,
    { id: practitionerId },
  ).data;

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await mutateAsync();
    refresh();
  };

  return (
    <>
      <ReactAdminButton
        label="Assign"
        onClick={(e) => {
          e.stopPropagation();
          void handleClick();
        }}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
        >
          <DialogTitle>
            {`Are you sure you want to assign ${firstName} ${lastName} to ${practitioner ? practitioner.firstName : ''} ${practitioner ? practitioner.lastName : ''}?`}
          </DialogTitle>
          <DialogActions>
            <ReactAdminButton
              label="Cancel"
              onClick={(e) => {
                e.stopPropagation();
                handleDialogClose();
              }}
            />
            <ReactAdminButton
              label="Confirm"
              onClick={(e) => {
                e.stopPropagation();
                void handleConfirm();
              }}
            />
          </DialogActions>
      </Dialog>
    </>
  );
};
