import { createContext } from "react";

interface IAppRoleContext {
  role: "SUPERUSER" | "CONTENT_CREATOR";
  setRole: (role: "SUPERUSER" | "CONTENT_CREATOR") => void;
}

const initialValue: IAppRoleContext = {
  role: "SUPERUSER",
  setRole() {
    null;
  },
};

export const AppRoleContext = createContext<IAppRoleContext>(initialValue);
