import { FC } from "react";

import { useInfoTable } from "./hooks";
import { TableComponent } from "./TableComponent";

interface InfoTableProps {
  headerLabel?: string;
  columns: Array<string>;
  resource: string;
  valueType?: "raw" | "percent";
  subtitle?: string;
  activityId?: string;
  institutionId?: string;
  practitionerId?: string;
}

export const InfoTable: FC<InfoTableProps> = ({
  headerLabel,
  columns,
  resource,
  valueType = "raw",
  subtitle,
  institutionId,
  activityId,
  practitionerId,
}) => {
  const { isLoading, lastMonth, tableConfig } = useInfoTable(
    resource,
    valueType,
    {
      activityId,
      institutionId,
      practitionerId,
    }
  );

  return (
    <TableComponent
      headerLabel={headerLabel}
      columns={columns}
      subtitle={subtitle}
      isLoading={isLoading}
      lastMonth={lastMonth}
      tableConfig={tableConfig}
    />
  );
};
