import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import {
  DateInput,
  Edit,
  Labeled,
  minValue,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
} from "react-admin";

import { editChoices } from "./constans";
import { UDI } from "./types";

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const EditUDI: FC = () => (
  <Edit<UDI> mutationMode="pessimistic">
    <SimpleForm toolbar={<UserEditToolbar />}>
      <Typography variant="h5">UDI configuration</Typography>
      <Labeled>
        <TextField source="udiDi" label="UDI-DI" />
      </Labeled>
      <Labeled>
        <TextField source="udiPi" label="UDI-PI" />
      </Labeled>
      <Typography variant="h5">Version configuration</Typography>
      <Labeled>
        <TextField source="mobileVersion" />
      </Labeled>
      <Labeled>
        <TextField source="webVersion" />
      </Labeled>
      <Labeled>
        <TextField source="apiVersion" />
      </Labeled>
      <Labeled>
        <TextField source="adminPanelVersion" />
      </Labeled>
      <Labeled>
        <TextField source="idpVersion" />
      </Labeled>
      <Typography variant="h5">Additional configuration</Typography>
      <SelectInput
        source="status"
        choices={editChoices}
        validate={[required()]}
      />
      <DateInput
        source="expiresAt"
        validate={[minValue(dayjs().startOf("d").toDate())]}
        helperText="Marks date when version would expire. Once set, it cannot be removed."
      />
    </SimpleForm>
  </Edit>
);

export default EditUDI;
