import GroupRemoveIcon from "@mui/icons-material/GroupRemove";

import RequestsList from "./requestsList";

export default {
  list: RequestsList,
  icon: GroupRemoveIcon,
  recordRepresentation: (record: { firstName: string; email: string }) =>
    `${record.firstName} (${record.email})`,
  options: { label: "Remove requests" },
};
