import PeopleIcon from "@mui/icons-material/People";

import PractitionersCreate from "./practitionersCreate";
import PractitionersEdit from "./practitionersEdit";
import PractitionersList from "./practitionersList";

export default {
  list: PractitionersList,
  create: PractitionersCreate,
  edit: PractitionersEdit,
  icon: PeopleIcon,
  recordRepresentation: (record: { name: string; role: string }) =>
    `${record.name} (${record.role})`,
  options: { label: "Practitioners" },
};
