import simpleRestProvider from "ra-data-simple-rest";
import { GetOneParams, RaRecord, withLifecycleCallbacks } from "react-admin";

import { activityLifecycleCallbacks } from "./activities/lifecycleCallbacks";
import { getHeadersForCustomDataProviderMethod, httpClient } from "./auth";
import { HealthCheckResponse } from "./dashboard/subtabs/monitoring";
import { Institution } from "./institutions/types";
import { journalLifecycleCallbacks } from "./journals/lifecycleCallbacks";

const baseDataProvider = simpleRestProvider(
  `/api/superuser`,
  httpClient,
  "X-Total-Count"
);

export const dataProvider = withLifecycleCallbacks(
  {
    ...baseDataProvider,
    enableInstitution: (
      institutionId: string,
      dataForEnable: { enabled: boolean; reason?: string }
    ) =>
      fetch(`/api/superuser/institutions/${institutionId}/enabled`, {
        method: "PUT",
        headers: getHeadersForCustomDataProviderMethod(),
        body: JSON.stringify(dataForEnable)
      }).then((response) => response.json() as Promise<Institution>),
    assignPractitionerToTeam: (
      institutionId: string,
      practitionerId: string,
      teamId: string
    ) =>
      fetch(
        `/api/superuser/institutions/${institutionId}/teams/${teamId}/practitioners`,
        {
          method: "POST",
          headers: getHeadersForCustomDataProviderMethod(),
          body: JSON.stringify({ userId: practitionerId })
        }
      ).then((response) => response.json() as Promise<unknown>),
    removePractitionerFromTeam: (
      institutionId: string,
      practitionerId: string,
      teamId: string
    ) =>
      fetch(
        `/api/superuser/institutions/${institutionId}/teams/${teamId}/practitioners/${practitionerId}`,
        {
          method: "DELETE",
          headers: getHeadersForCustomDataProviderMethod()
        }
      ).then((response) => response.json() as Promise<unknown>),
    assignPractitionerToInstitution: (
      institutionId: string,
      practitionerId: string,
      roles: Array<string>
    ) =>
      fetch(`/api/superuser/institutions/${institutionId}/practitioners`, {
        method: "POST",
        headers: getHeadersForCustomDataProviderMethod(),
        body: JSON.stringify({ userId: practitionerId, roles: roles })
      }).then((response) => response.json() as Promise<unknown>),
    removePractitionerFromInstitution: async (
      institutionId: string,
      practitionerId: string
    ) => {
      const response = await fetch(
        `/api/superuser/institutions/${institutionId}/practitioners/${practitionerId}`,
        {
          method: "DELETE",
          headers: getHeadersForCustomDataProviderMethod()
        }
      );

      if (!response.ok) {
        const error = await response.json() as Promise<{code: number, message: string}>
        throw new Error(JSON.stringify(error));
      }

      return response;
    },
    assignPatientToPractitioner: (
      institutionId: string,
      practitionerId: string,
      patientId: string
    ) =>
      fetch(
        `/api/superuser/institutions/${institutionId}/practitioners/${practitionerId}/patients`,
        {
          method: "POST",
          headers: getHeadersForCustomDataProviderMethod(),
          body: JSON.stringify({ userId: patientId })
        }
      ).then((response) => response.json() as Promise<unknown>),
    removePatientFromPractitioner: (
      institutionId: string,
      practitionerId: string,
      patientId: string
    ) =>
      fetch(
        `/api/superuser/institutions/${institutionId}/practitioners/${practitionerId}/patients/${patientId}`,
        {
          method: "DELETE",
          headers: getHeadersForCustomDataProviderMethod()
        }
      ).then((response) => response.json() as Promise<unknown>),
    assignPatientToInstitution: (institutionId: string, patientId: string) =>
      fetch(`/api/superuser/institutions/${institutionId}/patients`, {
        method: "POST",
        headers: getHeadersForCustomDataProviderMethod(),
        body: JSON.stringify({ userId: patientId })
      }).then((response) => response.json() as Promise<unknown>),
    removePatientFromInstitution: (institutionId: string, patientId: string) =>
      fetch(
        `/api/superuser/institutions/${institutionId}/patients/${patientId}`,
        {
          method: "DELETE",
          headers: getHeadersForCustomDataProviderMethod()
        }
      ),
      removeUserData: (userId: string) =>
      fetch(
        `/api/superuser/users/${userId}/content`,
        {
          method: "DELETE",
          headers: getHeadersForCustomDataProviderMethod()
        }
      ),
      addRemoveRequest: async (userId: string, reason: string) => {
        const response = await fetch(
          `/api/superuser/users/remove-requests`,
          {
            method: "POST",
            headers: getHeadersForCustomDataProviderMethod(),
            body: JSON.stringify({ userId, reason })
          }
        );

        if (!response.ok) {
          const error = await response.json() as Promise<{code: number, message: string}>
          throw new Error(JSON.stringify(error));
        }

        return response;
      },
      forceRemoveAccount: async (userId: string, reason: string, role: 'PAT' | 'MHP') => {
        try {
          const response = await fetch(
            `/api/superuser/users/force-remove`,
            {
              method: "POST",
              headers: getHeadersForCustomDataProviderMethod(),
              body: JSON.stringify({ userId, reason, role })
            }
          );
      
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
      
          return response;
        } catch (error) {
          console.error('Error occurred during deletion:', error);
          throw error;
        }
      },
      healthCheck: (system?: 'app' | 'idp') => {
        let url = "/health";

        if (system) {
          url += `/${system}`;
        }

        return fetch(
          url,
          {
            method: "GET",
            headers: getHeadersForCustomDataProviderMethod()
          }
        ).then(async (response) => {
          if (!response.ok) {
            throw new Error(`Health check failed with status: ${response.status}`);
          }
          return response.json() as Promise<HealthCheckResponse>;
        })
      },
      getOne: async <RecordType extends RaRecord>(resource: string, params: GetOneParams<RecordType>) => {
        const { id, ...restParams } = params;
        const queryString = Object.entries(restParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
            .join('&');
    
        const url = `/api/superuser/${resource}/${String(id)}?${queryString}`;
    
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: getHeadersForCustomDataProviderMethod()
          });
          if (!response.ok) {
            throw new Error('API response was not ok');
          }
          const data = await (response.json() as Promise<RecordType>);
          return { data };
        } catch (error) {
          console.error('Cannot fetch data:', error);
          throw error;
        }
      }
  },
  [activityLifecycleCallbacks, journalLifecycleCallbacks]
);
