import QuizIcon from "@mui/icons-material/Quiz";

import ActivityCreate from "./activityCreate";
import ActivityEdit from "./activityEdit";
import ActivityList from "./activityList";
import ActivityShow from "./activityShow";
import { Activity } from "./types";

export default {
  list: ActivityList,
  edit: ActivityEdit,
  create: ActivityCreate,
  show: ActivityShow,
  icon: QuizIcon,
  recordRepresentation: (record: Activity) =>
    `${record.title}${record.version ? `, version: ${record.version}` : ""}, ${
      record.status
    }`
};
