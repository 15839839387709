import { SxProps } from "@mui/material";
import { CSSProperties } from "react";

export const commonItemsStyling: SxProps = {
  ".RaSimpleFormIterator-line": {
    borderBottomWidth: "0",
    borderLeft: "solid 2px #2196f3",
    marginBottom: "40px",
    paddingLeft: "5px",
    transition: '.5s linear all',
  },
  ".RaSimpleFormIterator-inline": {
    paddingLeft: "5px",
    width: "100%",
  },
};

export const commonAnswerOptionsListStyling: SxProps = {
  ".RaSimpleFormIterator-list": {
    borderLeft: "solid 2px #2196f3",
  },
};

export const commonAnswerOptionsStyling: SxProps = {
  ".RaSimpleFormIterator-line": {
    borderBottomWidth: "0",
    borderLeftWidth: "0",
    marginBottom: "40px",
  },
};

export const commonStringArrayOptionsStyling: SxProps = {
  ".RaSimpleFormIterator-line": {
    borderBottomWidth: "0",
    borderLeftWidth: "0",
    paddingLeft: "5px",
  },
};

export const commonToolbarStyling: SxProps = {
  position: "fixed",
  left: 0,
  bottom: 0,
  zIndex: 100,
  width: "100%",
};

export const commonCardStyling: CSSProperties = {
  boxShadow: '0px 4px 4px 0px #00000040',
}

export const commonCardContainerStyling: CSSProperties = {
  display: 'flex',
  width: '100%',
}
