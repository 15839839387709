import { Box, Button } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AutocompleteInput, ReferenceInput, SelectArrayInput, SelectInput, TextInput, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { AppRoleContext } from 'src/context/AppRoleContext/AppRoleContext';

import { auditMetricChoices, userTypeChoices } from '../types';

const PostFilterForm = () => {
    const { role } = useContext(AppRoleContext);
    const [userType, setUserType] = useState('');
    const { setFilters } = useListContext();

    const form = useForm({
        defaultValues: {},
    });

    const isFirstRender = useRef(true);

    const onSubmit = (values) => {
        setFilters(values, [], false);
    };

    const handleClear = () => {
        setFilters({}, [], false);
        form.reset();
    };

    const handleUserTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserType(event.target.value);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            setFilters({}, [], false);
            isFirstRender.current = false;
        }
    }, [setFilters]);
    
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit) as (event: React.BaseSyntheticEvent) => void}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                    <Box component="span" mr={2}>
                        <SelectInput
                            source="userType"
                            choices={userTypeChoices}
                            label="User Type"
                            onChange={handleUserTypeChange}
                            helperText=""
                        />
                    </Box>
                    {role === "SUPERUSER" ? (
                        <Box component="span" mr={2}>
                            {(userType === 'MHP' || userType === 'PAT') && (
                                <ReferenceInput
                                    source="userSub"
                                    reference={userType === 'MHP' ? 'users/practitioners' : 'users/patients'}
                                    label="User"
                                    perPage={100}
                                >
                                <AutocompleteInput 
                                    optionText={({ firstName, lastName }: { firstName: string; lastName: string; }) => `${firstName} ${lastName}`}
                                    helperText=""
                                />
                                </ReferenceInput>
                            )}
                        </Box>
                        ) : (
                        <div />
                    )}
                    <Box component="span" mr={2}>
                        <ReferenceInput
                            source="institutionId"
                            reference="institutions"
                            label="Institution"
                            perPage={50}
                            helperText=""
                        >
                            <AutocompleteInput optionText="name" helperText="" />
                        </ReferenceInput>
                    </Box>
                    <Box component="span" mr={2}>
                        <TextInput
                            resettable
                            source="url"
                            label="URL"
                            helperText=""
                        />
                    </Box>
                    <Box component="span" mr={2}>
                        <SelectArrayInput
                            source="metric"
                            choices={auditMetricChoices.sort((a, b) => a.name.localeCompare(b.name))}
                            label="Metrics"
                            helperText=""
                        />
                    </Box>
                    <Box component="span" mr={2} mb={1.5}>
                        <Button variant="outlined" color="primary" type="submit">
                            Filter
                        </Button>
                    </Box>
                    <Box component="span" mb={1.5}>
                        <Button variant="outlined" onClick={handleClear}>
                            Clear filters
                        </Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};

export default PostFilterForm;