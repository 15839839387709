import { Box } from "@mui/material";
import { useContext } from "react";
import { Datagrid, ExportButton, FunctionField, List, Pagination, ReferenceField, TextField, TopToolbar } from "react-admin";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import PostFilterForm from "./subforms/filterForm";
import { AuditTrial } from "./types";

const ListActions = () => (
  <Box width="100%">
      <TopToolbar>
          <ExportButton />
      </TopToolbar>
      <PostFilterForm />
  </Box>
);

const AuditTrailsList = () => {
  const { role } = useContext(AppRoleContext);

  return (
    <List actions={<ListActions />} pagination={<Pagination rowsPerPageOptions={[5, 10, 25]} />}>
      <Datagrid bulkActionButtons={false}>
      <FunctionField
        label="User"
        render={(record: AuditTrial) => {
          if (role === "SUPERUSER") {
            if (record.userType === "MHP") {
              return (
                <ReferenceField source="userId" reference="users/practitioners" emptyText="-">
                  <>
                    <TextField source="firstName" /> <TextField source="lastName" />
                  </>
                </ReferenceField>
              );
            } else if (record.userType === "PAT") {
              return (
                <ReferenceField source="userId" reference="users/patients" emptyText="-">
                  <>
                    <TextField source="firstName" /> <TextField source="lastName" />
                  </>
                </ReferenceField>
              );
            } else if (record.userType === "SUPERUSER") {
              return <span>-</span>;
            } else {
              return <span>-</span>;
            }
          } else {
            return <span>-</span>;
          }
        }}
      />
      <ReferenceField
          label="Institution"
          source="institutionId"
          reference="institutions"
          emptyText="-"
          sortable={false}
      >
      </ReferenceField>
      <TextField source="userType" sortable={false} emptyText="-"/>
      <TextField source="url" sortable={false} emptyText="-"/>
      <FunctionField
          label="Metric"
          render={(record: AuditTrial) => {
            const arrayData = record.metric;
            return arrayData.length > 0 ? arrayData.join(", ") : "-";
          }}
        />
      </Datagrid>
    </List>
  );
}

export default AuditTrailsList;
