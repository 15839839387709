import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import {
  Button as ReactAdminButton,
  useDataProvider,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";

import { CustomDataProvider } from "../../../auth";
import { Institution, Practitioner } from "../../types";

export const RemoveFromInstitutionButton: FC = () => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const institutionId = pathname.split("/")[2] || "";
  const { id, firstName, lastName } = useRecordContext<Practitioner>();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();

  const institution = useGetOne<Institution>(
    `institutions`, { id: institutionId }
  ).data;

  const { mutateAsync } = useMutation(() =>
    dataProvider.removePractitionerFromInstitution(institutionId, id)
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    try {
      await mutateAsync();
      refresh();
    } catch (error) {
      const errorResponse = error as Error
      let errorMessage = 'Unknown error occurred';

      if (errorResponse.message) {
        const errorData = JSON.parse(errorResponse.message) as {
          statusCode: number;
          message: string;
          error: string;
        };

        if (errorData &&
           errorData.statusCode === 400 &&
           errorData.message === 'practitioner.lastAdminInInstitution') {
          errorMessage = 'Unassigning the last practitioner from the institution is not allowed';
        }
      }

      notify(errorMessage, { type: 'error' });
      setOpen(false);
    }
  };

  return (
    <>
      <ReactAdminButton
        label="Remove"
        onClick={(e) => {
          e.stopPropagation();
          void handleClick();
        }}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        onClick={(e) => e.stopPropagation()}
        >
          <DialogTitle>
            {`Are you sure you want to unassign ${firstName} ${lastName} from ${institution?.name}?`}
          </DialogTitle>
          <DialogActions>
            <ReactAdminButton
              label="Cancel"
              onClick={(e) => {
                e.stopPropagation();
                handleDialogClose();
              }}
            />
            <ReactAdminButton
              label="Confirm"
              onClick={(e) => {
                e.stopPropagation();
                void handleConfirm();
              }}
            />
          </DialogActions>
      </Dialog>
    </>
  );
};
