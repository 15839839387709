import { Institution, Team } from "./types";

export const transformInstitutionDataBeforeRequest = (data: Institution) => {
  const dataToTransform = data;

  dataToTransform.id = undefined;
  dataToTransform.createdBy = undefined;
  dataToTransform.updatedBy = undefined;
  dataToTransform._deleted = undefined;
  dataToTransform.createdAt = undefined;
  dataToTransform.updatedAt = undefined;
  dataToTransform.disabled = undefined;
  dataToTransform.disabledReason = undefined;

  return dataToTransform;
};

export const transformTeamDataBeforeRequest = (data: Team) => {
  const dataToTransform = data;

  dataToTransform.id = undefined;

  return dataToTransform;
};
