export const userTypeChoices = [
    { id: 'MHP', name: 'MHP' },
    { id: 'PAT', name: 'PAT' },
    { id: 'SUPERUSER', name: 'SUPERUSER' },
    { id: 'SYSTEM', name: 'SYSTEM' },
];

export interface AuditTrial {
    userId: string;
    institutionId: string;
    userType: string;
    url: string;
    metric: Array<string>
}

export const auditMetricChoices = [
    { id: 'ACTIVITY_FEEDBACK', name: 'ACTIVITY_FEEDBACK'},
    { id: 'ACTIVITY_TIME_SPENT', name: 'ACTIVITY_TIME_SPENT'},
    { id: 'ACTIVITY_ASSIGNED', name: 'ACTIVITY_ASSIGNED'},
    { id: 'OCCURRENCE_COMPLETED', name: 'OCCURRENCE_COMPLETED'},
    { id: 'OCCURRENCE_OVERDUE', name: 'OCCURRENCE_OVERDUE'},
    { id: 'LOGIN', name: 'LOGIN'},
    { id: 'REGISTER', name: 'REGISTER'},
    { id: 'REFRESH_TOKEN', name: 'REFRESH_TOKEN'},
    { id: 'ACCOUNT_CREATED', name: 'ACCOUNT_CREATED'},
    { id: 'ACCOUNT_REMOVED', name: 'ACCOUNT_REMOVED'},
    { id: 'INSTITUTION_CREATED', name: 'INSTITUTION_CREATED'},
    { id: 'INVITATION_CREATED', name: 'INVITATION_CREATED'},
    { id: 'INVITATION_ACCEPTED', name: 'INVITATION_ACCEPTED'},
    { id: 'INVITATION_OVERDUE', name: 'INVITATION_OVERDUE'},
    { id: 'ACCOUNT_DEACTIVATED', name: 'ACCOUNT_DEACTIVATED'},
    { id: 'ACCOUNT_ACTIVATED', name: 'ACCOUNT_ACTIVATED'},
    { id: 'ACCOUNT_DELETION_REQUESTED', name: 'ACCOUNT_DELETION_REQUESTED'},
    { id: 'GOAL_ADDED', name: 'GOAL_ADDED'},
    { id: 'GOAL_EDITED', name: 'GOAL_EDITED'},
    { id: 'GOAL_REMOVED', name: 'GOAL_REMOVED'},
    { id: 'GOAL_COMPLETED', name: 'GOAL_COMPLETED'},
    { id: 'RESOURCE_ADDED', name: 'RESOURCE_ADDED'},
    { id: 'RESOURCE_EDITED', name: 'RESOURCE_EDITED'},
    { id: 'RESOURCE_REMOVED', name: 'RESOURCE_REMOVED'},
    { id: 'MENTAL_CHALLENGE_ADDED', name: 'MENTAL_CHALLENGE_ADDED'},
    { id: 'MENTAL_CHALLENGE_EDITED', name: 'MENTAL_CHALLENGE_EDITED'},
    { id: 'MENTAL_CHALLENGE_REMOVED', name: 'MENTAL_CHALLENGE_REMOVED'},
    { id: 'SKILL_ADDED', name: 'SKILL_ADDED'},
    { id: 'SKILL_EDITED', name: 'SKILL_EDITED'},
    { id: 'SKILL_REMOVED', name: 'SKILL_REMOVED'},
    { id: 'PREVENTION_ADDED', name: 'PREVENTION_ADDED'},
    { id: 'PREVENTION_EDITED', name: 'PREVENTION_EDITED'},
    { id: 'PREVENTION_REMOVED', name: 'PREVENTION_REMOVED'},
    { id: 'VULNERABILITY_ADDED', name: 'VULNERABILITY_ADDED'},
    { id: 'VULNERABILITY_EDITED', name: 'VULNERABILITY_EDITED'},
    { id: 'VULNERABILITY_REMOVED', name: 'VULNERABILITY_REMOVED'},
    { id: 'WARNING_SIGN_ADDED', name: 'WARNING_SIGN_ADDED'},
    { id: 'WARNING_SIGN_EDITED', name: 'WARNING_SIGN_EDITED'},
    { id: 'WARNING_SIGN_REMOVED', name: 'WARNING_SIGN_REMOVED'},
    { id: 'PERCEPTION_ADDED', name: 'PERCEPTION_ADDED'},
    { id: 'PERCEPTION_EDITED', name: 'PERCEPTION_EDITED'},
    { id: 'PERCEPTION_REMOVED', name: 'PERCEPTION_REMOVED'},
    { id: 'EMERGENCY_DETECTION_ADDED', name: 'EMERGENCY_DETECTION_ADDED'},
    { id: 'EMERGENCY_DETECTION_EDITED', name: 'EMERGENCY_DETECTION_EDITED'},
    { id: 'EMERGENCY_DETECTION_REMOVED', name: 'EMERGENCY_DETECTION_REMOVED'},
    { id: 'DIAGNOSIS_ADDED', name: 'DIAGNOSIS_ADDED'},
    { id: 'DIAGNOSIS_REMOVED', name: 'DIAGNOSIS_REMOVED'},
    { id: 'PERSONAL_CONTACT_ADDED', name: 'PERSONAL_CONTACT_ADDED'},
    { id: 'PERSONAL_CONTACT_EDITED', name: 'PERSONAL_CONTACT_EDITED'},
    { id: 'PERSONAL_CONTACT_REMOVED', name: 'PERSONAL_CONTACT_REMOVED'},
    { id: 'PROFESSIONAL_SUPPORT_ADDED', name: 'PROFESSIONAL_SUPPORT_ADDED'},
    { id: 'PROFESSIONAL_SUPPORT_EDITED', name: 'PROFESSIONAL_SUPPORT_EDITED'},
    { id: 'PROFESSIONAL_SUPPORT_REMOVED', name: 'PROFESSIONAL_SUPPORT_REMOVED'},
    { id: 'USER_ADDED_TO_INSTITUTION', name: 'USER_ADDED_TO_INSTITUTION'},
    { id: 'USER_REMOVED_FROM_INSTITUTION', name: 'USER_REMOVED_FROM_INSTITUTION'},
]