import { Datagrid, List, TextField } from "react-admin";
import { Statistics } from "src/common/statistics";

const PathwayList = () => (
  <List aside={<Statistics />}>
    <Datagrid
      rowClick="edit"
      bulkActionButtons={false}
      sx={{
        "& .column-title": { width: "50%" }
      }}
    >
      <TextField source="title" />
      <TextField source="status" />
      <TextField source="activityCount" />
      <TextField source="timeSpan" label="Time span (days)" />
      <TextField source="duration" label="Duration (minutes)" />
    </Datagrid>
  </List>
);

export default PathwayList;
