import { Box, Tab, Tabs } from "@mui/material";
import { FC, PropsWithChildren, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const InstitutionEdit: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(location.pathname.split("/")[3] ?? "");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault()
    setValue(newValue);
    const [_, institutions, id] = location.pathname.split("/");
    navigate(["", institutions, id, newValue].join("/"));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="institution tabs"
        >
          <Tab label="Info" value="" />
          <Tab label="Teams" value={"teams"} />
          <Tab label="Practitioners" value={"practitioners"} />
          <Tab label="Patients" value={"patients"} />
          <Tab label="Onboarding" value={"onboarding"} />
        </Tabs>
      </Box>
      {children}
    </Box>
  );
};

export default InstitutionEdit;
