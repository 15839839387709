import { FC } from "react";
import { useContext } from "react";
import { ChipField, Datagrid, DateField, FunctionField, List, ListProps, ReferenceArrayField, ReferenceField, SingleFieldList, TextField } from "react-admin";
import { AuditTrial } from "src/auditTrails/types";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

import { RemoveUserButton } from "./removeUserButton";
import { RemoveRequest } from "./types";


const RequestsList: FC<ListProps<RemoveRequest>> = () => {
  const { role } = useContext(AppRoleContext);
  
  return (  
    <List title="Remove Account Requests">
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          label="User"
          render={(record: AuditTrial) =>
            role === "SUPERUSER" ? (
              record.userType === "MHP" ? (
                <ReferenceField source="userId" reference='users/practitioners' emptyText={`-`}>
                  <>
                    <TextField source="firstName" /> <TextField source="lastName" />
                  </>
                </ReferenceField>
              ) : (
                <ReferenceField source="userId" reference='users/patients' emptyText={`-`}>
                  <>
                    <TextField source="firstName" /> <TextField source="lastName" />
                  </>
                </ReferenceField>
              )
            ) : (
              <span>-</span>
            )
          }
        />
        <DateField source="createdAt" label="Requested Date"/>
        <TextField source="requestType" label="Account Type"/>
        <ReferenceArrayField
            label="Institutions"
            reference="institutions"
            source="institutionsIds"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        <RemoveUserButton />
      </Datagrid>
    </List>
  )
};

export default RequestsList;
