import { FC } from "react";
import {
  ArrayInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import {
  commonAnswerOptionsListStyling,
  commonStringArrayOptionsStyling,
} from "../commonStyling";
import { AccessibilityRestrictionChoices } from "../selectChoices/accessibilityRestrictionChoices";
import { activityModuleChoices } from "../selectChoices/activityModuleChoices";
import { IndicationChoices } from "../selectChoices/indicationChoices";
import { RecommendedFrequencyChoices } from "../selectChoices/recommendedFrequencyChoices";

export const ActivityProfessionalInformation: FC = () => (
  <>
    <TextInput source="activityGoal" validate={required()} fullWidth multiline />
    <TextInput source="instruction" fullWidth multiline />
    <ArrayInput source="modules" sx={commonAnswerOptionsListStyling}>
      <SimpleFormIterator sx={commonStringArrayOptionsStyling}>
        <SelectInput
          source=""
          validate={required()}
          fullWidth
          label="Module Name"
          choices={activityModuleChoices}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <ArrayInput source="indication" sx={commonAnswerOptionsListStyling}>
      <SimpleFormIterator sx={commonStringArrayOptionsStyling}>
        <SelectInput
          source=""
          validate={required()}
          fullWidth
          label="Indication Name"
          choices={IndicationChoices}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput source="contraIndication" fullWidth multiline />
    <SelectInput
      source="recommendedFrequency"
      choices={RecommendedFrequencyChoices}
      fullWidth
    />
    <SelectInput
      source="accessibilityRestriction"
      choices={AccessibilityRestrictionChoices}
      fullWidth
    />
    <ArrayInput source="references" sx={commonAnswerOptionsListStyling}>
      <SimpleFormIterator sx={commonStringArrayOptionsStyling}>
        <TextInput source="label" validate={required()} fullWidth />
        <TextInput source="link" type="url" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput
      source="feedback"
      fullWidth
      helperText="You can provide a link to an external service, where therapists can leave feedback about this activity"
    />
  </>
);
