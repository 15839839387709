export const PathwayTimeRelationChoices = [
  {
    id: "RELATIVE",
    name: "RELATIVE"
  },
  {
    id: "ABSOLUTE",
    name: "ABSOLUTE"
  }
];
