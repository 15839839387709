import { useMemo } from "react";
import { useGetList } from "react-admin";

export const useRatingChart = (activityId: string, institutionId?: string) => {
  const { data = [], isLoading } = useGetList<{
    value: number;
    count: number;
    id: undefined;
  }>("statistics/time-spent", {
    filter: {
      activityId,
      institutionId,
    },
  });

  const { x, y } = useMemo(() => {
    const x: Array<number> = [];
    const y: Array<number> = [];
    if (isLoading) {
      return {
        x,
        y,
      };
    }

    for (const dataPoint of data) {
      x.push(dataPoint.value);
      y.push(dataPoint.count);
    }

    return {
      x,
      y,
    };
  }, [data, isLoading]);

  const avg = useMemo(() => {
    const timeSum = data.reduce(
      (prev, curr) => prev + curr.count * curr.value,
      0
    );

    const occurrencesSum = data.reduce((prev, curr) => prev + curr.count, 0);

    return occurrencesSum ? timeSum / occurrencesSum : 0
  }, [data]);

  return {
    x,
    y,
    avg,
    isLoading,
  };
};
