import { FormHelperText } from "@mui/material";
import { FC } from "react";
import {
  minValue,
  required,
  SelectInput,
  TextInput,
  Validator,
} from "react-admin";
import { ActivityItemTypeChoices } from "src/activities/selectChoices/activityItemTypeChoices";
import { StatusChoices } from "src/activities/selectChoices/statusChoices";
import { ActivityItems } from "src/activities/subforms/activityItems";
import { ActivityItem, ActivityItemType } from "src/activities/types";
import { CustomNumberInput } from "src/components/CustomNumberInput";

export const JournalPageOverview: FC = () => {
  const filteredChoices = ActivityItemTypeChoices.filter(
    (choice) => choice.id !== ActivityItemType.PAGE_BREAK
  );

  const smileyScaleValidator: Validator = (value: Array<ActivityItem>) => {
    let smileyScaleCount = 0;

    for (const item of value) {
      if (
        item.activityItemType === ActivityItemType.PATIENT_RESPONSE_SMILEY_SCALE
      ) {
        smileyScaleCount++;
      }

      if (smileyScaleCount > 1) {
        return "Cannot create a Journal Page with multiple 'Smiley Scale' items";
      }
    }

    return undefined;
  };

  return (
    <>
      <TextInput source="title" fullWidth />
      <SelectInput
        source="status"
        validate={required()}
        choices={StatusChoices}
        fullWidth
      />
      <TextInput
        source="feature"
        validate={required()}
        fullWidth
        multiline
        helperText={
          <FormHelperText >
            Feature will be shown to both <b>practitioner</b> and <b>patient</b> in the journal evaluation, as a <b>question name</b>
          </FormHelperText>
        }
      />
      <CustomNumberInput
        source="suggestedDuration"
        validate={minValue(0)}
        fullWidth
      />
      <CustomNumberInput
        source="version"
        validate={[required(), minValue(1)]}
        fullWidth
      />
      <ActivityItems
        choices={filteredChoices}
        additionalValidators={[smileyScaleValidator]}
      />
    </>
  );
};
