import { FC } from "react";
import {
  AutocompleteArrayInput,
  ImageField,
  ImageInput,
  minValue,
  ReferenceArrayInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import { CustomNumberInput } from "src/components/CustomNumberInput";

import { ActivityTypeChoices } from "../selectChoices/activityTypeChoices";
import { LanguageChoices } from "../selectChoices/languageChoices";
import { StatusChoices } from "../selectChoices/statusChoices";

export const ActivityOverview: FC = () => (
  <>
    <TextInput source="title" validate={required()} fullWidth />
    <SelectInput
      source="activityType"
      validate={required()}
      choices={ActivityTypeChoices}
      fullWidth
    />
    <TextInput source="description" validate={required()} fullWidth multiline />
    <CustomNumberInput source="suggestedDuration" validate={minValue(0)} fullWidth />
    <ImageInput
      source={"picture"}
      label="Activity picture"
      accept="image/jpeg, image/png, image/webp, image/jpg"
      multiple={false}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
    <SelectInput
      source="status"
      validate={required()}
      choices={StatusChoices}
      fullWidth
    />
    <SelectInput
      source="language"
      validate={required()}
      choices={LanguageChoices}
      fullWidth
    />
    <TextInput source="author" validate={required()} fullWidth />
    <TextInput source="semanticIdentifier" validate={required()} fullWidth />
    <CustomNumberInput
      source="version"
      validate={[required(), minValue(1)]}
      fullWidth
    />
    <ReferenceArrayInput
      label="Applies to institutions"
      source="institutionIds"
      reference="institutions"
    >
      <AutocompleteArrayInput fullWidth />
    </ReferenceArrayInput>
  </>
);

export default ActivityOverview;
