import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { Datagrid, DateField, Filter, List, ReferenceField, ReferenceInput, SearchInput, SelectInput, TextField } from "react-admin";
import { AppRoleContext } from "src/context/AppRoleContext/AppRoleContext";

const OnboardingFilter = (props) => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <ReferenceInput label="Institution" source="institutionId" reference="institutions" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
  
const OnboardingsList = () => {
  const { role } = useContext(AppRoleContext);

  if (role !== 'SUPERUSER') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <Typography variant="h5">
          You do not have permissions to access this view.
        </Typography>
      </Box>
    );
  }

  return (
    <List filters={<OnboardingFilter />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="firstName" sortable={false}/>
        <TextField source="lastName" sortable={false}/>
        <TextField source="email" sortable={false}/>
        <DateField
          source="birthdate"
          sortable={false}
      />
      <ReferenceField
          label="Institution"
          source="institutionId"
          reference="institutions"
          emptyText="-"
          sortable={false}
      >
      </ReferenceField>
      <DateField
          source="lastSendingDate"
          label="Last invitation resend"
          showTime
          sortable={false}
      />
      </Datagrid>
    </List>
  );
}

export default OnboardingsList;
