import { Datagrid, List, TextField } from "react-admin";
import { Statistics } from "src/common/statistics";

const JournalPageList = () => (
  <List aside={<Statistics />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="title" />
      <TextField source="status" />
    </Datagrid>
  </List>
);

export default JournalPageList;
