export const ActivityItemTypeChoices = [
  { id: "SECTION_TITLE", name: "Section title" },
  { id: "PAGE_BREAK", name: "Page break" },
  { id: "PRACTITIONER_INPUT_TEXT", name: "Practitioner input text" },
  { id: "PRACTITIONER_INPUT_IMAGE", name: "Practitioner input image" },
  { id: "PRACTITIONER_INPUT_AUDIO", name: "Practitioner input audio" },
  {
    id: "PATIENT_RESPONSE_SHORT_ANSWER",
    name: "Patient response short answer",
  },
  { id: "PATIENT_RESPONSE_LONG_ANSWER", name: "Patient response long answer" },
  { id: "PATIENT_RESPONSE_NUMBER", name: "Patient response number" },
  {
    id: "PATIENT_RESPONSE_SINGLE_CHOICE",
    name: "Patient response single choice",
  },
  {
    id: "PATIENT_RESPONSE_MULTIPLE_CHOICE",
    name: "Patient response multiple choice",
  },
  { id: "PATIENT_RESPONSE_SORTING", name: "Patient response sorting" },
  {
    id: "PATIENT_RESPONSE_LIKERT_SCALE",
    name: "Patient response likert scale",
  },
  {
    id: "PATIENT_RESPONSE_LINEAR_SCALE",
    name: "Patient response linear scale",
  },
  {
    id: "PATIENT_RESPONSE_PATIENT_RECORD",
    name: "Patient response patient record",
  },
  {
    id: "PATIENT_RESPONSE_SMILEY_SCALE",
    name: "Patient response smiley scale",
  },
  {
    id: "PATIENT_RESPONSE_TIME_INPUT_ANSWER",
    name: "Patient response time answer",
  },
];
