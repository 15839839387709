import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import {
  BooleanInput,
  Button as ReactAdminButton,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  Labeled,
  List,
  maxLength,
  ReferenceField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,  useRefresh} from "react-admin";
import { useMutation } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { CustomDataProvider } from "../auth";
import { useMutationErrorNotify } from "../common/handleMutationError";
import { phoneNumberValidation } from "../common/phoneNumberValidation";
import { GenderChoices } from "./genderChoices";
import { transformEditDataBeforeRequest } from "./transformDataBeforeRequest";

const UserEditToolbar = (props) => { 
  const notify = useNotify();
  const { id } = useParams();
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [forceRemoveAccount, setForceRemoveAccount] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [reasonError, setReasonError] = useState('');
  const record = useRecordContext();
  const dataProvider = useDataProvider<CustomDataProvider>();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const { mutateAsync: removeUserDataAsync, isLoading: isRemovingUserData } = useMutation(() =>
    dataProvider.removeUserData(id)
  );

  const { mutateAsync: addRemoveRequestAsync, isLoading: isAddingRemoveRequest } = useMutation((reason: string) =>
    dataProvider.addRemoveRequest(id, reason)
  );

  const { mutateAsync: forceRemoveAccountAsync, isLoading: isForceRemovingAccount } = useMutation((reason: string) =>
    dataProvider.forceRemoveAccount(id, reason, 'PAT'),
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value); 
  };

  const handleOmitRemoveAccountFlow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceRemoveAccount(event.target.checked);
  };

  const handleDataClick = () => setDataDialogOpen(true);
  const handleDataDialogClose = () => setDataDialogOpen(false);
  const handleDataConfirm = async () => {
    await removeUserDataAsync();
    refresh();
    setDataDialogOpen(false);
  };

  const handleDeleteClick = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);
  const handleDeleteConfirm = async (reason: string) => {
    try {
      if (!reason.trim()) {
        setReasonError('Reason for deletion cannot be empty.');
        return;
      }

      let notifyText = 'User removed';
  
      if (forceRemoveAccount) {
        await forceRemoveAccountAsync(reason);
      } else {
        notifyText = 'Remove request added'
        await addRemoveRequestAsync(reason);
      }

      redirect('/users/patients');
      notify(notifyText, {type: 'success' });
      setDeleteDialogOpen(false);
    } catch (error) {
      const errorResponse = error as Error
      let errorMessage = 'Error occurred during deletion';
    
      if (errorResponse.message) {
        const errorData = JSON.parse(errorResponse.message) as {
          statusCode: number;
          message: string;
          error: string;
        };

        if (errorData &&
           errorData.statusCode === 400 &&
           errorData.message === 'removeAccReq.alreadyExists') {
          errorMessage = 'Deletion Request already exists';
        }
      }
  
      notify(errorMessage, { type: 'error' });
      setDeleteDialogOpen(false);
    }
  };
  
  return (
    <>
      <Toolbar {...props}>
        <SaveButton />
        <ReactAdminButton disabled={isAddingRemoveRequest} label="Delete" onClick={handleDeleteClick} startIcon={<DeleteOutlineIcon />} style={{
          backgroundColor: 'red',
          color: 'white',
          fontSize: '0.875rem',
          borderRadius: '4px',
          marginLeft: '8px',
          padding: '8px 16px'
        }} /> 
        {record && record.isTesting && (
          <ReactAdminButton disabled={isRemovingUserData} label="Remove data" onClick={handleDataClick} startIcon={<FolderDeleteIcon />} style={{
            marginLeft: 'auto',
            marginRight: '25%',
            backgroundColor: 'orange',
            color: 'white',
            fontSize: '0.875rem',
            borderRadius: '4px',
            padding: '8px 16px'
          }} /> 
        )}
      </Toolbar>
      <Dialog
        open={dataDialogOpen}
        onClose={handleDataDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {`Are you sure you want to remove all user's data?`}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for deletion" 
            defaultValue={inputValue}
            onChange={handleInputChange}
            fullWidth 
            margin="normal" 
          />
        </DialogContent>
        <DialogActions>
          <ReactAdminButton
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              handleDataDialogClose();
            }}
          />
          <ReactAdminButton
            label="Confirm"
            onClick={(e) => {
              e.stopPropagation();
              void handleDataConfirm();
            }}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          {`Are you sure you want to request account deletion?`}
        </DialogTitle>
        <DialogContent>
        <div>
          {forceRemoveAccount && (
            <div style={{ color: 'orange', marginTop: '8px', marginBottom: '8px' }}>
            Warning: It will remove the user account, and this action cannot be reversed.
          </div>
          )}
          <div style={{ marginBottom: '8px' }}>
            <label>
              <input
                type="checkbox"
                checked={forceRemoveAccount}
                onChange={handleOmitRemoveAccountFlow}
              />
              Do you want to omit remove account request flow and force delete user?
            </label>
          </div>
          <div>
            <label htmlFor="reason">Reason for deletion:</label>
            <input
                type="text"
                id="reason"
                value={inputValue}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
            />
          </div>
        </div>
        {reasonError && (
          <Typography variant="body2" color="error">
            {reasonError}
          </Typography>
        )}
        </DialogContent>
        <DialogActions>
          <ReactAdminButton
            label="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteDialogClose();
            }}
          />
          <ReactAdminButton
            label="Confirm"
            disabled={isForceRemovingAccount}
            onClick={(e) => {
              e.stopPropagation();
              void handleDeleteConfirm(inputValue);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const PatientsEdit = () => {
  const notify = useMutationErrorNotify();
  const { pathname } = useLocation();
  const patientId = pathname.split("/")[3] || "";

  return (
    <Box display="flex" flexDirection="column" gap="50px">
      <Edit
        title="Patients"
        mutationMode="pessimistic"
        transform={transformEditDataBeforeRequest}
        mutationOptions={{
          onError: notify,
        }}
      >
        <SimpleForm toolbar={<UserEditToolbar />}>
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            gap="3%"
          >
            <ImageField source="picture.src" />
            <Labeled>
              <TextField source="email" />
            </Labeled>
            <Labeled>
              <DateField source="birthdate" />
            </Labeled>
            <Labeled>
              <DateField source="lastLogin" emptyText="-" fullWidth showTime />
            </Labeled>
            <TextInput
              source="firstName"
              validate={[required(), maxLength(200)]}
              fullWidth
            />
            <TextInput
              source="lastName"
              validate={[required(), maxLength(200)]}
              fullWidth
            />
            <SelectInput source="gender" choices={GenderChoices} fullWidth />
            <TextInput source="pronouns" validate={maxLength(200)} fullWidth />
            <TextInput source="sex" validate={maxLength(200)} fullWidth />
            <TextInput source="contactEmail" type="email" fullWidth />
            <TextInput
              source="contactPhoneNumber"
              validate={phoneNumberValidation}
              fullWidth
            />
            <BooleanInput
              source="isTesting"
            />
          </Box>
        </SimpleForm>
      </Edit>

      <Box>
        <Typography>Patient institutions</Typography>
        <List
          title=" "
          pagination={false}
          actions={false}
          resource={`users/patients/${patientId}/institutions`}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={(id) => `institutions/${id}`}
          >
            <TextField source="name" />
          </Datagrid>
        </List>
      </Box>

      <Box paddingBottom="50px">
        <Typography>Patient MHPs</Typography>
        <List
          title=" "
          pagination={false}
          actions={false}
          resource={`users/patients/${patientId}/practitioners`}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="firstName" />
            <TextField source="lastName" />
          </Datagrid>
        </List>
      </Box>

      <Box paddingBottom="50px">
        <Typography>Consents</Typography>
        <List
          title=" "
          pagination={false}
          actions={false}
          resource={`users/${patientId}/consents`}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              label="Consent"
              source="policyId"
              reference="policies"
              emptyText="-"
            />
            <DateField source="consentAt" />
            <DateField source="revokedAt" emptyText="-" />
          </Datagrid>
        </List>
      </Box>
    </Box>
  );
};

export default PatientsEdit;
