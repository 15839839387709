import { Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import { Count } from "react-admin";

interface InfoCardProps {
  label?: string;
  resource: string;
  activityId?: string;
  institutionId?: string;
  practitionerId?: string;
}

export const InfoCard: FC<InfoCardProps> = ({
  label,
  resource,
  activityId,
  institutionId,
  practitionerId,
}) => (
  <Card raised sx={{ width: 380 }}>
    <CardContent
      sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#4F4F4F",
          textAlign: "left",
        }}
      >
        {label ?? resource}
      </Typography>
      <Count
        resource={resource}
        filter={{ isTesting: false, activityId, institutionId, practitionerId }}
        typography="h5"
        sx={{ color: "#4F4F4F", textAlign: "right" }}
      />
    </CardContent>
  </Card>
);
