import { Box } from "@mui/material";
import { Edit, SaveButton, TabbedForm, Toolbar } from "react-admin";

import { useMutationErrorNotify } from "../common/handleMutationError";
import JournalIntroductionForm from "./subforms/journalIntroduction";
import { JournalIntroductionItems } from "./subforms/journalIntroductionItems";
import { JournalOverview } from "./subforms/journalOverview";
import { JournalPages } from "./subforms/journalPages";
import { JournalProfessionalInformation } from "./subforms/journalProfessionalInformations";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";

const JournalEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const JournalEdit = () => {
  const notify = useMutationErrorNotify();

  return (
    <Edit
      title="Journal"
      transform={transformDataBeforeRequest}
      mutationMode="pessimistic"
      mutationOptions={{
        onError: notify,
      }}
    >
      <TabbedForm toolbar={<JournalEditToolbar />}>
        <TabbedForm.Tab label="Overview">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <JournalOverview />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Professional Information">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <JournalProfessionalInformation />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Introduction Overview">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <JournalIntroductionForm />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Introduction Items">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <JournalIntroductionItems />
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Pages">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <JournalPages />
          </Box>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export default JournalEdit;
