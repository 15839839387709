import { Box } from "@mui/material";
import { Create, SimpleForm } from "react-admin";
import { useMutationErrorNotify } from "src/common/handleMutationError";

import PolicyForm from "./policyForm";

const PolicyCreate = () => {
  const notify = useMutationErrorNotify();

  return (
    <Create
      title="Policy"
      mutationOptions={{
        onError: notify
      }}
    >
      <SimpleForm>
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: "100%", xl: 1414 }}
          paddingBottom="100px"
          gap="3%"
        >
          <PolicyForm />
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default PolicyCreate;
