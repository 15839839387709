import { ActivityModule } from "../types";

export const activityModuleChoices: Array<{
  id: ActivityModule;
  name: string;
}> = [
  {
    id: ActivityModule.BEHAVIOR_ACTIVATION,
    name: "Behavior activation",
  },
  {
    id: ActivityModule.COMPLETION,
    name: "Completion",
  },
  {
    id: ActivityModule.NEEDS_AND_VALUES,
    name: "Needs and values",
  },
  {
    id: ActivityModule.PREPARATION,
    name: "Preparation",
  },
  {
    id: ActivityModule.PSYCHOEDUCATION_DEPRESSION,
    name: "Psychoeducation Depression",
  },
  {
    id: ActivityModule.RETHINKING,
    name: "Rethinking",
  },
  {
    id: ActivityModule.TREATMENT_PLAN,
    name: "Treatment plan",
  },
];
