
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';

import OnboardingsList from './onboardingsList';

export default {
  list: OnboardingsList,
  icon: ReduceCapacityIcon,
  options: { label: 'Onboarding list' }
};
