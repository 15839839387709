import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";
import { FC } from "react";
import { Count } from "react-admin";

interface StatisticsProps {
  resource?: string;
  onlyTotal?: boolean;
}

const statisticsTypography: TypographyProps = {
  variant: "body2",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const Statistics: FC<StatisticsProps> = ({ resource, onlyTotal }) => {
  return (
    <div style={{ width: 120, margin: "4em 1em" }}>
      <Typography variant="h6">Statistics</Typography>
      <Typography {...statisticsTypography}>
        <span>Total:</span>
        <span>
          <Count resource={resource} />
        </span>
      </Typography>
      {!onlyTotal && (
        <Typography {...statisticsTypography}>
          <span>Active:</span>
          <span>
            <Count resource={resource} filter={{ isTesting: false }} />
          </span>
        </Typography>
      )}
      {!onlyTotal && (
        <Typography {...statisticsTypography}>
          <span>Testing:</span>
          <span>
            <Count resource={resource} filter={{ isTesting: true }} />
          </span>
        </Typography>
      )}
    </div>
  );
};
