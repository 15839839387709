import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  CssBaseline,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { forwardRef, useContext, useMemo } from "react";
import {
  AppBar,
  Count,
  Layout as AdminLayout,
  Logout,
  Menu,
  MenuProps,
  useResourceDefinitions,
  UserMenu,
  useUserMenu,
} from "react-admin";

import { keycloakClient } from "./auth";
import { AppRoleContext } from "./context/AppRoleContext/AppRoleContext";

const CustomAppRoleToggle = forwardRef<HTMLAnchorElement>((props, ref) => {
  const { role, setRole } = useContext(AppRoleContext);

  const label = useMemo(() => {
    switch (role) {
      case "CONTENT_CREATOR":
        return "Content creator";
      default:
        return "Superuser";
    }
  }, [role]);

  const value = useMemo(() => role === "SUPERUSER", [role]);

  const onChange = (value: boolean) => {
    if (value) {
      setRole("SUPERUSER");
    } else {
      setRole("CONTENT_CREATOR");
    }
  };

  return (
    <MenuItem ref={ref} href={""} {...props}>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={({ target: { checked } }) => onChange(checked)}
          />
        }
        label={label}
      />
    </MenuItem>
  );
});

CustomAppRoleToggle.displayName = "CustomAppRoleToggle";

const CustomUserAccountButton = forwardRef<HTMLAnchorElement>((props, ref) => {
  const { onClose } = useUserMenu();

  return (
    <MenuItem
      onClick={() => {
        void keycloakClient.accountManagement();
        onClose;
      }}
      ref={ref}
      href={""}
      {...props}
    >
      <ListItemIcon>
        <PersonIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText sx={{ color: "rgba(0,0,0,0.6)" }}>
        Manage Account
      </ListItemText>
    </MenuItem>
  );
});

CustomUserAccountButton.displayName = "CustomUserAccountButton";

const CustomAppBar = () => (
  <AppBar
    userMenu={
      <UserMenu>
        <CustomAppRoleToggle />
        <CustomUserAccountButton />
        <Logout />
      </UserMenu>
    }
  />
);

const CustomMenu = (props: MenuProps) => {
  const resources = useResourceDefinitions();

  const {
    hasDashboard,
    children = [
      hasDashboard ? (
        <Menu.DashboardItem key="default-dashboard-menu-item" />
      ) : null,
      ...Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => (
          <Box
            key={name}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Menu.ResourceItem name={name} />
            <Typography flex="0" sx={{ paddingRight: 2 }}>
              <Count resource={name} />
            </Typography>
          </Box>
        )),
    ],
    ...rest
  } = props;

  return <Menu {...rest}>{children}</Menu>;
};

const Layout = (props) => (
  <>
    <CssBaseline />
    <AdminLayout {...props} appBar={CustomAppBar} menu={CustomMenu} />
  </>
);

export default Layout;
