import PolicyIcon from "@mui/icons-material/Policy";

import { Policy } from "./interfaces";
import PolicyCreate from "./policyCreate";
import PolicyEdit from "./policyEdit";
import PolicyList from "./policyList";
import PolicyShow from "./policyShow";

export default {
  list: PolicyList,
  create: PolicyCreate,
  edit: PolicyEdit,
  show: PolicyShow,
  icon: PolicyIcon,
  recordRepresentation: (record: Policy) =>
    `${record.type}/${record.role} (${record.version}), ${record.status}`
};
