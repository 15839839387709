import dayjs from "dayjs";
import { useMemo, useRef } from "react";
import { useGetList, UseGetListHookValue } from "react-admin";

interface IUseInfoTable {
  isLoading: boolean;
  lastMonth: string;
  tableConfig: Array<{
    month: string;
    values: Array<{ value: string; change: number }>;
  }>;
}

function printValue(val1: number | undefined, val2: number | undefined) {
  if (typeof val1 === "undefined" || typeof val2 === "undefined") {
    return "";
  }

  return `${val1}/${val2}`;
}

function calculateValue(val1: number | undefined, val2: number | undefined) {
  if (!val2) {
    return 0;
  }

  return (val1 ?? 0) / val2;
}

const useMonthData = (
  monthNumber: number,
  today: dayjs.Dayjs,
  activityId?: string,
  institutionId?: string,
  practitionerId?: string
) => {
  const label = today
    .subtract(monthNumber, "month")
    .startOf("month")
    .format("MMM YYYY");
  const dataDeletionRequests = useGetList<undefined>(
    "statistics/deletion-requests",
    {
      filter: {
        isTesting: false,
        from: today
          .subtract(monthNumber, "month")
          .startOf("month")
          .toISOString(),
        to: today.subtract(monthNumber, "month").endOf("month").toISOString(),
        activityId,
        institutionId,
        practitionerId,
      },
      pagination: { perPage: 1, page: 1 },
    }
  );

  const dataAllUsers = useGetList<undefined>("statistics/all-patients", {
    filter: {
      isTesting: false,
      from: today.subtract(monthNumber, "month").startOf("month").toISOString(),
      to: today.subtract(monthNumber, "month").endOf("month").toISOString(),
      activityId,
      institutionId,
      practitionerId,
    },
    pagination: { perPage: 1, page: 1 },
  });

  return {
    dataDeletionRequests,
    dataAllUsers,
    label,
    data: {
      isLoading: dataDeletionRequests.isLoading || dataAllUsers.isLoading,
    },
  };
};

const useTableData = (
  monthA: {
    dataDeletionRequests: UseGetListHookValue<undefined>;
    dataAllUsers: UseGetListHookValue<undefined>;
    label: string;
  },
  monthB: {
    dataDeletionRequests: UseGetListHookValue<undefined>;
    dataAllUsers: UseGetListHookValue<undefined>;
    label: string;
  }
): {
  month: string;
  values: Array<{ value: string; change: number }>;
} => ({
  month: monthA.label,
  values: [
    {
      value: printValue(
        monthA.dataDeletionRequests.total,
        monthA.dataAllUsers.total
      ),
      change: 0,
    },
    {
      value: `${(
        calculateValue(
          monthA.dataDeletionRequests.total,
          monthA.dataAllUsers.total
        ) * 100
      ).toFixed()}%`,
      change:
        calculateValue(
          monthA.dataDeletionRequests.total,
          monthA.dataAllUsers.total
        ) -
        calculateValue(
          monthB.dataDeletionRequests.total,
          monthB.dataAllUsers.total
        ),
    },
  ],
});

export const useDeletionRequestInfoTable = (
  activityId?: string,
  institutionId?: string,
  practitionerId?: string
): IUseInfoTable => {
  const today = useRef(dayjs().startOf("d"));

  const { total: lastMonthDeletions, isLoading: lastMonthDeletionsIsLoading } =
    useGetList("statistics/deletion-requests", {
      filter: {
        isTesting: false,
        from: today.current.subtract(30, "d").toISOString(),
        to: today.current.toISOString(),
        activityId,
        institutionId,
        practitionerId,
      },
      pagination: { perPage: 1, page: 1 },
    });

  const { total: lastMonthPatients, isLoading: lastMonthPatientsIsLoading } =
    useGetList("statistics/all-patients", {
      filter: {
        isTesting: false,
        from: today.current.subtract(30, "d").toISOString(),
        to: today.current.toISOString(),
        activityId,
        institutionId,
        practitionerId,
      },
      pagination: { perPage: 1, page: 1 },
    });

  const month1 = useMonthData(
    1,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month2 = useMonthData(
    2,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month3 = useMonthData(
    3,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month4 = useMonthData(
    4,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month5 = useMonthData(
    5,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month6 = useMonthData(
    6,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month7 = useMonthData(
    7,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month8 = useMonthData(
    8,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month9 = useMonthData(
    9,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month10 = useMonthData(
    10,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month11 = useMonthData(
    11,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month12 = useMonthData(
    12,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );
  const month13 = useMonthData(
    13,
    today.current,
    activityId,
    institutionId,
    practitionerId
  );

  const isLoading = useMemo(
    () =>
      lastMonthDeletionsIsLoading ||
      lastMonthPatientsIsLoading ||
      month1.data.isLoading ||
      month2.data.isLoading ||
      month3.data.isLoading ||
      month4.data.isLoading ||
      month5.data.isLoading ||
      month6.data.isLoading ||
      month7.data.isLoading ||
      month8.data.isLoading ||
      month9.data.isLoading ||
      month10.data.isLoading ||
      month11.data.isLoading ||
      month12.data.isLoading ||
      month13.data.isLoading,
    [
      lastMonthDeletionsIsLoading,
      lastMonthPatientsIsLoading,
      month1.data.isLoading,
      month10.data.isLoading,
      month11.data.isLoading,
      month12.data.isLoading,
      month13.data.isLoading,
      month2.data.isLoading,
      month3.data.isLoading,
      month4.data.isLoading,
      month5.data.isLoading,
      month6.data.isLoading,
      month7.data.isLoading,
      month8.data.isLoading,
      month9.data.isLoading,
    ]
  );

  const deletions = useMemo(
    () => lastMonthDeletions ?? 0,
    [lastMonthDeletions]
  );
  const patients = useMemo(() => lastMonthPatients ?? 0, [lastMonthPatients]);
  const deletionPercentage = useMemo(
    () => (patients > 0 ? ((deletions / patients) * 100).toFixed(0) : ""),
    [deletions, patients]
  );

  return {
    isLoading,
    lastMonth: `${deletions}/${patients} ${deletionPercentage}%`,
    tableConfig: [
      useTableData(month1, month2),
      useTableData(month2, month3),
      useTableData(month3, month4),
      useTableData(month4, month5),
      useTableData(month5, month6),
      useTableData(month6, month7),
      useTableData(month7, month8),
      useTableData(month8, month9),
      useTableData(month9, month10),
      useTableData(month10, month11),
      useTableData(month11, month12),
      useTableData(month12, month13),
    ],
  };
};
